export const factsMarquee = () => ({

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            const marqueeImages = Array.from(this.$refs.factsMarquee.querySelectorAll('.marquee-content'));
            const positions = marqueeImages.map(() => 0);

            function animateMarquee() {
                positions.forEach((position, index) => {
                    positions[index] += 0.2;
                    marqueeImages[index].style.transform = `translateX(-${positions[index]}px)`;

                    // Перевіряємо, чи зображення досягло кінця marquee, і перекидаємо його на початок, якщо так
                    if (positions[index] >= marqueeImages[index].offsetWidth) {
                        positions[index] = 0;
                    }
                });

                requestAnimationFrame(animateMarquee);
            }
            animateMarquee();


            // reverse
            const marqueeImagesReverse = Array.from(this.$refs.factsMarqueeReverse.querySelectorAll('.marquee-content'));
            const positions2 = marqueeImagesReverse.map(() => 0);

            function animateMarqueeReverse() {
                positions2.forEach((position, index) => {
                    positions2[index] += 0.2;
                    marqueeImagesReverse[index].style.transform = `translateX(${positions2[index]}px)`;

                    // Перевіряємо, чи зображення досягло кінця marquee, і перекидаємо його на кінець, якщо так
                    if (positions2[index] >= 0) {
                        positions2[index] = -marqueeImagesReverse[index].offsetWidth;
                    }
                });

                requestAnimationFrame(animateMarqueeReverse);
            }
            animateMarqueeReverse();
        })
    },
});
