import axios from "axios";

export const formSubmit = () => ({
    isSuccess: false,
    isSuccessLoading: false,
    isSuccessMessage: false,

    init() {
        document.addEventListener("DOMContentLoaded", () => {
            this.initFormSubmit();
        });
    },

    initFormSubmit() {
        document
            .getElementById("form-submit")
            .addEventListener("submit", this.submitData.bind(this));
    },

    async submitData(e) {
        e.preventDefault();

        const requestURL = "https://script.google.com/macros/s/AKfycbyiBbJAYvcHEm7FRMmaJwfmm6Zxe_CPmSzHa-nZyq_USQMpaeuYZmw2AIhrANgCrtkk/exec";
        const formData = new FormData(e.target);

        this.isSuccess = true;
        this.isSuccessLoading = true;

        try {
            const response = await axios({
                method: "post",
                url: requestURL,
                data: formData,
            });
            this.isSuccessLoading = false;
            this.isSuccessMessage = true;
        } catch (error) {
            console.error("ERROR:", error);
        } finally {
            e.target.reset();
        }

        setTimeout(() => {
            this.isSuccess = false;
            this.isSuccessMessage = false;
        }, 3000);
    },
});
