export const copyText = () => ({
    text: '',
    success: false,

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.text = this.$refs.copyText.innerText;
        })
    },

    async copyTextToClipboard() {
        try {
            await navigator.clipboard.writeText(this.text);
            this.success = true;

            setTimeout(() => {
                this.success = false;
            }, 1000)
        } catch (err) {
            console.error('Error in copying text: ', err);
            this.success = false;
        }
    }
})