import { localesContent } from '../vendor/localesContent'

function getCheckedLocale(locale) {
    let checkedLocale = locale;

    if (!localesContent[`${locale}`]) {
        checkedLocale = "en";
    }

    return checkedLocale;
}

export function getCurrentLocale() {
    let locale;
    let params = new URL(document.location).searchParams;
    let searchLang = params.get("lang");

    if (searchLang) {
        return getCheckedLocale(searchLang);
    }

    if (!window.localStorage.getItem("location")) {
        fetch("https://ipinfo.io?token=9b63d6ffe0f230")
        .then(response => response.json())
        .then(data => {
            if (data.country) {
                window.localStorage.setItem("location", data.country.toLowerCase());
            }
            document.location.reload();
        });
    }

    if (window.localStorage.getItem("location")) {
        locale = window.localStorage.getItem("location").toLowerCase();
    } else {
        locale = "en";
    }

    return getCheckedLocale(locale);
}
