export const flags = {
    en: "<svg width=\"42\" height=\"24\" viewBox=\"0 0 16 11\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
        "<rect x=\"0.112305\" y=\"0.023407\" width=\"15.8719\" height=\"10.8597\" fill=\"#223EA3\"/>\n" +
        "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.78326 0.023407L0.0908203 0.0451264L0.11254 1.69413L14.2986 10.9065L16.0003 10.8756L15.9685 9.2366L1.78326 0.023407Z\" fill=\"white\"/>\n" +
        "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0.947666 0.023407L0.112305 0.858768L15.1488 10.8831L15.9842 10.0477L0.947666 0.023407Z\" fill=\"#C7152A\"/>\n" +
        "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.3139 0.0235596H15.9846V1.69428C15.9846 1.69428 6.16914 7.87261 1.79853 10.9066C1.7459 10.9434 0.129481 10.9091 0.129481 10.9091L0 9.32113L14.3139 0.0235596Z\" fill=\"white\"/>\n" +
        "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.1847 0L15.9842 0.858751L0.947666 10.8831L0.112305 10.0477L15.1847 0Z\" fill=\"#C7152A\"/>\n" +
        "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.95983 0.023407H10.1366V3.36485H15.9842V7.54166H10.1366V10.8831H5.95983V7.54166H0.112305V3.36485H5.95983V0.023407Z\" fill=\"white\"/>\n" +
        "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.79519 0.023407H9.30128V4.20021H15.9842V6.70629H9.30128V10.8831H6.79519V6.70629H0.112305V4.20021H6.79519V0.023407Z\" fill=\"#C7152A\"/>\n" +
        "</svg>",
    ua: "<svg width=\"42\" height=\"24\" viewBox=\"0 0 16 13\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
        "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0H16V6.09524H0V0Z\" fill=\"#0266C3\"/>\n" +
        "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 6.09525H16V12.1905H0V6.09525Z\" fill=\"#FFE600\"/>\n" +
        "</svg>"
}
