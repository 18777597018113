export const marqueeAnimation = () => ({
    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initMarquee()
        });
    },

    initMarquee() {
        const marqueeImages = Array.from(this.$refs.footerMarquee.querySelectorAll('.marquee-content'));
        const positions = marqueeImages.map(() => 0);

        function animateMarquee() {
            positions.forEach((position, index) => {
                positions[index] += 1; // Змінено значення з 2 на 1
                marqueeImages[index].style.transform = `translateX(-${positions[index]}px)`;

                // Перевіряємо, чи зображення досягло кінця marquee, і перекидаємо його на початок, якщо так
                if (positions[index] >= marqueeImages[index].offsetWidth) {
                    positions[index] = 0;
                }
            });

            requestAnimationFrame(animateMarquee);
        }

        animateMarquee();
    }
});
