import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

export const header = () => ({
    init() {
        // document.addEventListener("DOMContentLoaded", () => {
        //     this.initMobileMenuToggle();
        // });
    },
    onCloseMobileMenu() {
        const navigation = document.querySelector('.header-nav');
        const headerButton = document.querySelector('.header-talk-button');
        const header = document.querySelector('.header');

        header.classList.add("fixed");

        navigation.style.visibility = 'hidden';
        navigation.style.position = 'absolute';

        navigation.style.opacity = 0;
        headerButton.style.visibility = 'hidden';
        headerButton.style.opacity = 0;

        document.body.style.overflow = "auto";
    },
    onOpenMobileMenu() {
        const navigation = document.querySelector('.header-nav');
        const headerButton = document.querySelector('.header-talk-button');
        const header = document.querySelector('.header');

        header.classList.remove("fixed");

        navigation.style.visibility = 'visible';
        navigation.style.position = 'fixed';
        navigation.style.opacity = 1;
        headerButton.style.visibility = 'visible';
        headerButton.style.opacity = 1;
        document.body.style.overflow = "hidden";
    }
});