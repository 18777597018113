export const factsAndFigures = () => ({
    isVideoOpen: false,
    hoursStrategicWork: 52,

    init() {
        this.initVideoActions();
        this.updateHoursStrategyWork();
    },

    updateHoursStrategyWork() {
        this.hoursStrategicWork = 1536;
    },

    initVideoActions() {
        const clickToggle = (element, callback1, callback2) => {
            let state = false;

            element.addEventListener("click", function (ev) {
                [callback2, callback1][(state ^= 1)].call(this, ev);
            });
        };

        const handleKeyPress = (event) => {
            if (!this.isVideoOpen) return;

            if (event.key === "Escape") {
                const video = document.getElementById("superhumans-video");
                document.exitFullscreen();
                video.pause();
                this.isVideoOpen = false;
            }
        };

        const superhumansVideo = document.getElementById("superhumans-video");

        clickToggle(superhumansVideo, function (e) {
            e.preventDefault();
            document.exitFullscreen();
            this.pause();
            this.isVideoOpen = false;
        }, function (e) {
            e.preventDefault();
            superhumansVideo.requestFullscreen();
            this.play();
            this.isVideoOpen = true;
        });

        const videoPlayBtn = document.getElementById("video-play-btn");

        videoPlayBtn.addEventListener("click", function (e) {
            e.preventDefault();
            const video = document.getElementById("superhumans-video");
            video.requestFullscreen();
            video.play();
            video.classList.remove("superhumans-video");
            this.isVideoOpen = true;
        });

        document.addEventListener("keydown", handleKeyPress.bind(this));
    }
});
