import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import AlpineI18n from "alpinejs-i18n";

import './_vendor';
import { mainLogic } from './components/mainLogic'
// import { headerMenu } from './store/headerMenu';
import {mobileMenu} from './store/mobileMenu';
import {stopScroll} from './store/stop-scroll';
import { header } from './components/header';
import { factsAndFigures } from './components/factsAndFigures'
import { marqueeAnimation } from './components/marqueeAnimation'
import { ourTeamSlider } from './components/ourTeamSlider'
import {sliderSwiper} from "./components/slider-swiper";
import {formSubmit} from "./components/formSubmit";
import { factsMarquee } from './components/factsMarquee'
import { donateLogic } from './components/donateLogic'
import { copyText } from './components/copyText'
import { hero } from './components/hero'
import { lottieBuild } from './lottie-build';
import { localesContent } from './vendor/localesContent'
import { localizationPanel } from './components/localizationPanel';
import { getCurrentLocale } from './utils/getCurrentLanguage';

lottieBuild();

const locale = getCurrentLocale();

document.addEventListener('alpine:init', () => {
    // Alpine.store('headerMenu', headerMenu);
    Alpine.store("mobileMenu", mobileMenu);
    Alpine.store("stopScroll", stopScroll);
    Alpine.store("language", { lang: locale });

    Alpine.data("sliderSwiper", sliderSwiper);
    Alpine.data("formSubmit", formSubmit);
    Alpine.data('localizationPanel', localizationPanel);
    Alpine.data('mainLogic', mainLogic);
    Alpine.data('header', header);
    Alpine.data('hero', hero);
    Alpine.data('marqueeAnimation', marqueeAnimation);
    Alpine.data('ourTeamSlider', ourTeamSlider);
    Alpine.data('factsAndFigures', factsAndFigures);
    Alpine.data('factsMarquee', factsMarquee);
    Alpine.data('donateLogic', donateLogic);
    Alpine.data('copyText', copyText);

    Alpine.plugin(collapse);
    Alpine.plugin(AlpineI18n);
})

document.addEventListener("alpine-i18n:ready", function () {
    window.AlpineI18n.create(locale, localesContent);

    window.AlpineI18n.fallbackLocale = 'en';
});

window.Alpine = Alpine

Alpine.start();
