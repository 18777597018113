export const donateLogic = () => ({
    open: false,
    customAmount: '',
    donateAmount: 0,
    activeCurrency: 'USD',
    activeDonateList: [],
    regularDonation: false,
    privacyAgreement: false,
    privacyAgreementError: false,
    currencyList: [
        {
            id: 1,
            name: 'USD'
        },
        {
            id: 2,
            name: 'UAH'
        }
    ],

    donateAmountUSD: [
        {
            id: 1,
            amount: 10,
            amountText: '10$'
        },
        {
            id: 2,
            amount: 50,
            amountText: '50$'
        },
        {
            id: 3,
            amount: 100,
            amountText: '100$'
        },
        {
            id: 4,
            amount: 150,
            amountText: '150$'
        }
    ],
    donateAmountUAH: [
        {
            id: 1,
            amount: 100,
            amountText: '100₴'
        },
        {
            id: 2,
            amount: 200,
            amountText: '200₴'
        },
        {
            id: 3,
            amount: 500,
            amountText: '500₴'
        },
        {
            id: 4,
            amount: 1000,
            amountText: '1000₴'
        }
    ],

    init() {
        this.activeDonateList = this.donateAmountUSD
        this.donateAmount = this.activeDonateList[0].amount
    },

    handlePrivacyAgreement(value) {
      if (value && this.privacyAgreementError) {
          this.privacyAgreementError = false;
      }

      this.privacyAgreement = value;
    },

    toggle() {
        if (this.open) {
            return this.close()
        }

        this.$refs.button.focus()

        this.open = true
    },
    close(focusAfter) {
        if (! this.open) return

        this.open = false

        focusAfter && focusAfter.focus()
    },

    updateDonateItems() {
        if (this.activeCurrency === 'UAH') {
            this.activeDonateList = this.donateAmountUAH
            this.donateAmount = this.donateAmountUAH[0].amount
        } else {
            this.activeDonateList = this.donateAmountUSD
            this.donateAmount = this.donateAmountUSD[0].amount
        }
    },

    onDonateBtnClick(btn) {
        this.donateAmount = +btn.dataset.donatAmount;
    },

    onInput(event) {
        if (!+event.target.value || +event.target.value <= 0) {
            this.updateDonateItems();
            return;
        }

        this.donateAmount = +event.target.value;
    },

    donateHandle() {
        if (!this.privacyAgreement) {
            this.privacyAgreementError = true;
            return;
        }

        let formData = {};

        formData = {
            amount: this.donateAmount,
            currency: this.activeCurrency,
            regular: this.regularDonation
        }

        //$ipsp https://docs.fondy.eu/en/docs/page/14/
        let button = $ipsp.get('button');

        button.setMerchantId(1531649);
        button.setAmount(formData.amount, formData.currency);
        button.setResponseUrl('https://www.42charity.foundation/');
        button.setHost('pay.fondy.eu');

        if (formData.regular) {
            button.setRecurringState(true);
            button.addRecurringData({
                start_time: new Date().toISOString().split('T')[0],
                // end_time: '',
                amount: formData.amount,
                period: 'month',
                every: 1
            });
        }

        console.log(formData);

        window.location.href = button.getUrl();
    }
})
