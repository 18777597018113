import Swiper, { Pagination, Autoplay } from 'swiper'
Swiper.use([Pagination, Autoplay])

export const ourTeamSlider = () => ({
    swiper: null,
    slideContentId: null,
    isMobile: window.innerWidth < 767,

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initSwiper();
        })
    },

    setSlideContentId(id) {
        this.slideContentId = id;
    },

    initSwiper() {
        const sliderWrapper = this.$refs.teamSlider;
        const dataReverseDirection = sliderWrapper.dataset.reverseDirection;
        let reverseDirection = false;

        if (dataReverseDirection && dataReverseDirection === "true") {
            reverseDirection = true;
        }

        this.swiper = new Swiper(sliderWrapper, {
            slidesPerView: 1,
            spaceBetween: 35,
            loop: true,
            autoplay: {
                delay: 1,
                disableOnInteraction: true,
                reverseDirection
            },
            speed: 8000,
            breakpoints: {
                600: {
                    slidesPerView: 'auto'
                }
            },
            pagination: {
                el: '.team-swiper-pagination',
                type: 'bullets',
                clickable: true,
                renderBullet: (index, className) => {
                    return `<span class="${className} !h-[8px] !w-[8px] !bg-alto !opacity-100 !mx-[6px] !rounded-none"></span>`;
                },
                bulletActiveClass: 'swiper-pagination-bullet-active !bg-malibu'
            },
            on: {
                slideChange: () => {
                    if (this.slideContentId) {
                        this.setSlideContentId(null);
                    }
                }
            }
        });

        this.calculateTextHeight();
    },

    calculateTextHeight() {
        if (!this.isMobile) {
            let items = document.querySelectorAll('#our-team .swiper-slide .description');
            let height = 0;
            items.forEach((item) => {
                item.style.height = "auto";
                if (item.offsetHeight > height) {
                    height = item.offsetHeight;
                }
            });
            items.forEach((item) => {
                item.style.height = height + 'px';
            });
        }
    }
})
