import { blackLogoData } from '../vendor/heroLottieAnimData'

export const hero = () => ({
    init() {
        document.addEventListener("DOMContentLoaded", () => {
            this.initHeroLottieAnimation();
        });
    },

    initHeroLottieAnimation() {
        const container = document.querySelector('#hero-animation');
        if (!container) return;

        bodymovin.loadAnimation({
            container,
            render: "svg",
            loop: true,
            autoplay: true,
            name: "header logo",
            animationData: blackLogoData
        });
    }
});
