import { flags } from "../vendor/flags";

export const localizationPanel = () => ({
    open: false,
    flags,

    toggle() {
        if (this.open) {
            return this.close()
        }

        this.$refs.button.focus()

        this.open = true
    },

    close(focusAfter) {
        if (! this.open) return

        this.open = false

        focusAfter && focusAfter.focus()
    }
});
