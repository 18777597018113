import Swiper, {Navigation, Pagination, Autoplay} from 'swiper';

Swiper.use([Pagination, Navigation, Autoplay]);

const sliderOptions = {
    hero: {
        slidesPerView: 1,
        speed: 2000,
        loop: true,
        autoplay: {
            delay: 2000,
        },
        pagination: {
            el: '.work-swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: (index, className) => {
                return `<span class="${className} !h-[8px] !w-[8px] lg:!w-[14px] lg:!h-[14px] !bg-alto !opacity-100 !mx-[6px] lg:!mx-[12px] !rounded-none"></span>`;
            },
            bulletActiveClass: 'swiper-pagination-bullet-active !bg-malibu'
        },
    },
    ourWork: {
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            665: {
                slidesPerView: 2,
            },
            950: {
                slidesPerView: 3,
            }
        },
        pagination: {
            el: '.work-swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: (index, className) => {
                return `<span class="${className} !h-[8px] !w-[8px] lg:!w-[14px] lg:!h-[14px] !bg-alto !opacity-100 !mx-[6px] lg:!mx-[12px] !rounded-none"></span>`;
            },
            bulletActiveClass: 'swiper-pagination-bullet-active !bg-malibu'
        },
    },
    partners: {
        slidesPerView: 2.3,
        spaceBetween: 35,
        loop: true,
        autoplay: {
            delay: 1,
            disableOnInteraction: true,
        },
        speed: 8000,
    },
    ourTeam: {
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            665: {
                slidesPerView: 2,
            },
            890: {
                slidesPerView: 2.5,
            },
            1090: {
                slidesPerView: 3,
            }
        },
        pagination: {
            el: '.work-swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: (index, className) => {
                return `<span class="${className} !h-[8px] !w-[8px] lg:!w-[14px] lg:!h-[14px] !bg-alto !opacity-100 !mx-[6px] lg:!mx-[12px] !rounded-none"></span>`;
            },
            bulletActiveClass: 'swiper-pagination-bullet-active !bg-malibu'
        },
    },
    '42-team-slider': {
        slidesPerView: 1.5,
        spaceBetween: 30,
        breakpoints: {
            665: {
                slidesPerView: 2.5,
            },
            890: {
                slidesPerView: 3.5,
            },
            1090: {
                slidesPerView: 5,
                spaceBetween: 50,
            }
        },
        pagination: {
            el: '.work-swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: (index, className) => {
                return `<span class="${className} !h-[8px] !w-[8px] lg:!w-[14px] lg:!h-[14px] !bg-alto !opacity-100 !mx-[6px] lg:!mx-[12px] !rounded-none"></span>`;
            },
            bulletActiveClass: 'swiper-pagination-bullet-active !bg-malibu'
        },
    }
}

export const sliderSwiper = (sliderOption) => ({
    slider: null,

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initSwiper();
        });
    },

    initSwiper() {
        this.slider = new Swiper(this.$el, sliderOptions[sliderOption]);
    },
});