export let localesContent = {
    en: {
        homepage: {
            header: {
                navItems: [
                    {
                        text: "Activities",
                        href: "#our-work"
                    },
                    {
                        text: "Partners",
                        href: "#partners"
                    },
                    {
                        text: "FOUNDERS",
                        href: "#our-team"
                    },
                    {
                        text: "Contacts",
                        href: "#footer"
                    },
                ],
                donationBtn: "Donate",
            },
            hero: {
                titleSlide1: "Website for the project of the Foundation of the President of Ukraine - Ukrainian-British scientific diaspora",
                titleSlide2: "Landings for superhumans projects with mastercard and blablacar",
                titleSlide3: "Site for the grant program 'Own business' - a project of the Ministry of Economy of Ukraine",
                titleSlide4: "Website and identity for the charity Unity Foundation",
                titleSlide5: "Identity for Dila Foundation",
                donationBtn: "more details"
            },
            factsAndFigures: {
                title: "Facts and figures",
                sectionDescription: "<span class=\"font-bold\">42 Foundation</span> relies on donations from individuals, corporations, and governments to carry out our missions. <span class=\"hidden md:inline\"><br></span> Here are some facts and figures that highlight our activites:",
                firstBlockTitleText: "",
                firstBlockDescription: "Number of hours of team work",
                secondBlockTitleNumber: "14",
                secondBlockTitleText: "",
                secondBlockDescription: "Number of projects supported",
                thirdBlockTitleNumber: "3",
                thirdBlockTitleText: "funds",
                thirdBlockDescription: "Total volume of help"
            },
            ourTeam: {
                opportunityTeamSubtitle: "Founders",
                teamSlides: [
                    {
                        img: "./img/main-page/ruslan-aliev3.png",
                        name: "RUSLAN ALIEV",
                        position: "Founder",
                        nextDoorLink: "mailto:aliiev@42charity.foundation",
                        facebookLink: "https://www.facebook.com/Ruslan.Aliiev.91",
                        linkedInLink: "https://linkedin.com/in/ruslan-aliiev",
                        instagramLink: "#",
                        twitterLink: "#"
                    },
                    {
                        img: "./img/main-page/dim-zrazhevsk2.png",
                        name: "Dim ZARAZHEVSKY",
                        position: "Co-founder",
                        nextDoorLink: "mailto:zarazhevskyi@42charity.foundation",
                        facebookLink: "https://www.facebook.com/Zarazhevsky/",
                        linkedInLink: "https://www.linkedin.com/in/dim-zarazhevsky-3a0963159/",
                        instagramLink: "https://www.instagram.com/zarazhevskyi/",
                        twitterLink: "#"
                    },
                    {
                        img: "./img/main-page/maksim-petrenko.png",
                        name: "Maksim Petrenko",
                        position: "Co-founder",
                        nextDoorLink: "mailto:petrenko@42charity.foundation",
                        facebookLink: "#",
                        linkedInLink: "https://www.linkedin.com/in/maks-petrenko-9640aa28b/",
                        instagramLink: "#",
                        twitterLink: "#"
                    }
                ]
            },
            ourWork: {
                title: "Our work",
                swiperSlides: [
                    {
                        img: "./img/main-page/our-work-1.png",
                        textCard: "logos and identity",
                    },
                    {
                        img: "./img/main-page/our-work-2.png",
                        textCard: "sites and online products",
                    },
                    {
                        img: "./img/main-page/our-work-3.png",
                        textCard: "strategic planning",
                    }
                ]
            },
            banner: {
                sectionTitle: "Doing good deeds together",
                title: "I want to help",
                textBtn: "Submit an application"
            },
            partners: {
                title: "Our partners"
            },
            case: {
                title: "Case",
                array: [
                    {
                        img: "./img/case/case-1.png",
                        status: "Active",
                        statusBg: "bg-malibu",
                        name: "Website for the PROJECT of the President of Ukraine's Fund",
                        description: "42 Foundation always supports state initiatives.",
                        btn: "Learn More",
                        href: "/",
                    },
                    {
                        img: "./img/case/case-2.png",
                        status: "Completed",
                        statusBg: "bg-darkGray",
                        name: "PRO BONO LANDINGS for Superhumans",
                        description: "The 42 Foundation team developed pro bono landing pages for the major Superhumans fundraising campaign.",
                        btn: "Learn More",
                        href: "/",
                    },
                    {
                        img: "./img/case/case-3.png",
                        status: "Active",
                        statusBg: "bg-malibu",
                        name: "Website for the Government Grant Program 'Vlasna Sprava'",
                        description: "The team developed the official website for the government grant program 'Vlasna Sprava'.",
                        btn: "Learn More",
                        href: "/",
                    },
                ],
            },
            donation: {
                title: "Donation",
                card: {
                    title: "Card",
                    description: "We do not directly process or store your card data.",
                    yourAmountPlaceholder: "Your amount",
                    oneTimeText: "One-time",
                    regularText: "Regular",
                    conversion: "The final amount charged may fluctuate due to the donation conversion from your currency to Ukrainian Hryvnia. In case of any questions or requests, please get in touch with us at ceo@42charity.foundation",
                    cardCharge: "Your card may be charged in Ukrainian Hryvnia. 1USD ~ 37 UAH",
                    privacyAgreement: `I have read the <a href="/public-offer.html" class="text-darkGray underline underline-offset-1">Public offer</a> to a charitable donation, <a href="/terms-and-conditions.html" class="text-darkGray underline underline-offset-1">Terms & Conditions</a> of the website and hereby agree to the processing of my personal data in accordance with Foundation's <a href="/privacy-policy.html" class="text-darkGray underline underline-offset-1">Privacy Policy</a>.`,
                    privacyAgreementLink: "Terms of Service",
                    requiredError: "This field is required!",
                    donateBtn: "Donate"
                },
                bankTransfer: {
                    title: "Bank Transfer",
                    beneficiary: {
                        name: "Beneficiary:",
                        value: "BO BLAGODIYNA ORGANIZACIYA «BLAGODIYNIY FOND „FUNDACIYA 42“"
                    },
                    EDPNOU: {
                        name: "EDPNOU (National State Registry of Ukrainian Enterprises and Organisations):",
                        value: "44980762"
                    },
                    bankBeneficiary: {
                        name: "Bank beneficiary:",
                        value: "JSC \"ACCENT-BANK\", Ukraine"
                    },
                    accounts: [
                        {
                            name: "Account # / IBAN: (UAH)",
                            value: "UA023077700000026002411155643"
                        },
                        {
                            name: "Account # / IBAN: (USD)",
                            value: "UA203077700000026001411155644"
                        },
                        {
                            name: "Account # / IBAN: (EUR)",
                            value: "UA383077700000026000411155645"
                        }
                    ],
                    swiftCode: {
                        name: "Swift Code:",
                        value: "UKCBUAUK"
                    }
                },
                payPal: {
                    title: "PayPal",
                    btn: "PAY WITH PAYPAL"
                },
                cryptocurrency: {
                    title: "Cryptocurrency",
                    items: [
                        {
                            name: "Bitcoin",
                            value: "bc1qkp67nkqagz6gqe0plvkskzh598kkdt560v3fne"
                        },
                        {
                            name: "Ethereum",
                            value: "0xAb9c934DA77Da0F753C7aC690a06b80B33bcdce6"
                        },
                        {
                            name: "USDT",
                            value: "TT1XunzkHpqtW7JCswVWaoh2CcQpRyryAv"
                        }
                    ]
                }
            },
            footer: {
                footerTopTitle: "Website developed by the team",
                contacts: {
                    title: "Contacts",
                    address: `22/24 Ivan Franko Street, </br> Shevchenkivskyi District, Kyiv, Ukraine, 01054`,
                },
                mapsWebsite: {
                    title: "Site Map",
                    mapsLink: [
                        {
                            textLink: "Activities",
                            href: "#our-work",
                        },
                        {
                            textLink: "Partners",
                            href: "#partners",
                        },
                        {
                            textLink: "Founders",
                            href: "#our-team",
                        },
                        {
                            textLink: "Doing Good Together",
                            href: "#banner",
                        },
                        {
                            textLink: "Media About Us",
                            href: "#",
                        },
                        {
                            textLink: "Help with Donations",
                            href: "#donation",
                        },
                        {
                            textLink: "News",
                            href: "#",
                        },
                    ]
                },
                links: {
                    title: "Links",
                    linksLink: [
                        {
                            textLink: "Foundation Policy",
                            href: "./privacy-policy.html",
                        },
                        {
                            textLink: "Public Offer",
                            href: "./public-offer.html",
                        },
                        {
                            textLink: "Terms of Use",
                            href: "./terms-and-conditions.html",
                        },
                        {
                            textLink: "Banking Details",
                            href: "#",
                        }
                    ]
                },
                privacyItems: [
                    {
                        name: "Terms and Conditions",
                        href: "/terms-and-conditions.html"
                    },
                    {
                        name: "Privacy Policy",
                        href: "/privacy-policy.html"
                    },
                ],
            },
        },
        mediaAbout: {
            title: "MEDIA ABOUT THE PROJECT",
            homepage: [
                {
                    img: "./img/media-about/forbes.png",
                    name: "The Ministry of Economy launched the website of the grant program 'Own Business'",
                    description: "The Ministry of Economy launched a website about the government grant program 'Own Business', which operates within the framework of the 'eWork' project. This was reported by the ministry's press service...",
                    btn: "Learn More",
                    href: "https://forbes.ua/news/minekonomiki-zapustilo-sayt-grantovoi-programi-vlasna-sprava-07032024-19709",
                },
                {
                    img: "./img/media-about/mind.png",
                    name: "The Ministry of Economy launched the official website of the grant program 'Own Business'",
                    description: "Now all the opportunities of the program, the conditions and algorithms for obtaining a grant, instructions for filling out a business plan, and current results of the program are collected on a separate landing page...",
                    btn: "Learn More",
                    href: "https://mind.ua/news/20270614-minekonomiki-zapustilo-oficijnij-sajt-grantovoyi-programi-vlasna-sprava",
                },
            ],
            economy: [
                {
                    img: "./img/media-about/forbes.png",
                    name: "The Ministry of Economy launched the website of the grant program 'Own Business'",
                    description: "The Ministry of Economy launched a website about the government grant program 'Own Business', which operates within the framework of the 'eWork' project. This was reported by the ministry's press service...",
                    btn: "Learn More",
                    href: "https://forbes.ua/news/minekonomiki-zapustilo-sayt-grantovoi-programi-vlasna-sprava-07032024-19709",
                },
                {
                    img: "./img/media-about/mind.png",
                    name: "The Ministry of Economy launched the official website of the grant program 'Own Business'",
                    description: "Now all the opportunities of the program, the conditions and algorithms for obtaining a grant, instructions for filling out a business plan, and current results of the program are collected on a separate landing page...",
                    btn: "Learn More",
                    href: "https://mind.ua/news/20270614-minekonomiki-zapustilo-oficijnij-sajt-grantovoyi-programi-vlasna-sprava",
                },
            ],
        },
        currentPage: {
            home: "Home",
            case: "Cases",
            found: "Website for the project of the President of Ukraine's Fund - \"Ukrainian-British Scientific Diaspora\"",
            economy: "Website for the Government Grant Program \"Own Business\"",
            superHumans: "Landing pages for Superhumans projects with Mastercard and BlaBlaCar",
            dila: "Rebranding for Dila Foundation",
            unity: "Website development and branding for Unity Foundation",
        },
        pageHero: {
            statusActive: "Active Project",
            statusDone: "Done Project",
            found: `Website for the Project </br> of the President of Ukraine's Foundation - </br> <span class="text-malibu">Ukrainian-British Scientific Diaspora</span>`,
            superHumans: `Landing Pages </br> for Superhumans Projects with <span class="text-malibu">Mastercard</span> and <span class="text-malibu">BlaBlaCar</span>`,
            economy: `Website for the Government </br> Grant Program <span class=\"text-malibu\">\"Own Business\"</span>`,
            dila: `Rebranding for </br> <span class=\"text-malibu\">Dila Foundation</span>`,
            unity: `Website Development and Branding for <span class=\"text-malibu\">Unity Foundation</span>`
        },
        sliderTeamPage: {
            found: {
                title: 'Website developed by the team',
                users: [
                    {
                        img: "./img/42-team/olena-moiseinko.png",
                        name: "Olena Moiseinko",
                        position: "Project manager",
                    },
                    {
                        img: "./img/42-team/tanya-kubyk.png",
                        name: "Tanya Kubyk",
                        position: "UI/UX designer",
                    },
                    {
                        img: "./img/42-team/diana-bidshehi.png",
                        name: "Diana Bidshehi",
                        position: "UI/UX designer",
                    },
                    {
                        img: "./img/42-team/maksym-pianchuk.png",
                        name: "Maksym Pianchuk",
                        position: "Front-end developer",
                    },
                    {
                        img: "./img/42-team/roman-hado.png",
                        name: "Roman Hado",
                        position: "Front-end developer",
                    },
                ]
            },
            unity: {
                title: 'Website developed by the team',
                users: [
                    {
                        img: "./img/42-team/olena-moiseinko.png",
                        name: "Olena Moiseinko",
                        position: "Project manager",
                    },
                    {
                        img: "./img/42-team/diana-bidshehi.png",
                        name: "Diana Bidshehi",
                        position: "UI/UX designer",
                    },
                    {
                        img: "./img/42-team/maksym-pianchuk.png",
                        name: "Maksym Pianchuk",
                        position: "Front-end developer",
                    },
                    {
                        img: "./img/42-team/roman-hado.png",
                        name: "Roman Hado",
                        position: "Front-end developer",
                    },
                ]
            },
            dila: {
                title: 'PROJECT DESIGNER',
                users: [
                    {
                        img: "./img/42-team/lilia-moisyeva.png",
                        name: "LILIA MOISYEVA",
                        position: "Graphic designer, art director",
                    },
                ]
            },
        },
        projectSupport: {
            title: "ABOUT PROJECT SUPPORT",
            userName: "Ruslan Aliev",
            userPosition: "IT Entrepreneur, Founder of 42 GROUP",
            found: {
                description: `For us, it is important to have the opportunity to unite the community of Ukrainian scientists abroad, to gather researchers for the reconstruction of education and science in Ukraine. </br> The project is designed to promote the reconstruction and development of Ukraine, as well as to integrate Ukrainian scientists into the global scientific community. </br> Based on my experience in teaching, I am confident that such initiatives provide growth points for scientists and open up new horizons.`
            },
            dila: {
                description: `The future of the state is a healthy nation. I believe that organizations not only working in the field of medicine but also engaged in charity in this area are doing an incredibly important job. Dila Foundation, with the support of the 42 Foundation, has launched a program for servicemen of the Armed Forces of Ukraine. Our defenders can receive free examinations as part of the initiative, which greatly facilitates health diagnostics and the maintenance of good well-being or reduces the time for pre-surgery preparation, as services in the network of laboratories are provided in the shortest time. We are proud of the partnership and happy to have the opportunity to create such projects together.`,
            },
            unity: {
                description: `Unity Foundation is a community of people who believe in the importance of helping others. They are not just partners in the usual sense of the word. They are part of our large family of like-minded individuals charged with helping those in need.<br><br> We believe that every person deserves the opportunity to live a full life, regardless of their circumstances. Therefore, we work every day to make the world a better place for all of us.`,
            },
            economy: {
                description: `Developing a website for the Ministry of Economy of Ukraine is not just a technical task of creating a platform for information; we are building a bridge between the government and citizens, a bridge that ensures information accessibility and interaction. Creating digital platforms is one of the key areas of work for the 42 Foundation. The case of launching a website for a grant program aimed at supporting businesses in Ukraine is an opportunity to indirectly help entrepreneurs in our country during this difficult time. More accessible information = more chances for receiving help.`,
            },
            superHumans: {
                description: `Superhumans is a project whose goal perfectly aligns with the values and one of the key directions of the 42 Foundation - providing accessible medical services to those affected by the war. Helping fellow citizens rediscover their superpower is a powerful idea. The project has several donors to support all areas, and our team supports the digital aspect of the project because this is what we do best.`,
            }
        },
        info: {
            infoTitle: 'Information',
            partnerTitle: 'Partner',
            servicesTitle: 'Services',
            found: {
                img: './img/found/found.png',
                text: `The 42 Foundation always supports state initiatives. Our partner is the President of Ukraine’s Fund for the Support of Education, Science, and Sports. We collaborated on the Ukrainian-British Scientific Diaspora project. The initiative aims to create a network of academic communities ready to invest their knowledge and expertise in rebuilding Ukraine. </br> This is a network of Ukrainian researchers in the United Kingdom, united for the development of science in Ukraine. Our team's task was to develop the Official Website of the Ukrainian-British Scientific Diaspora.`,
                partnerText: 'President of Ukraine’s Fund for the Support of Education, Science, and Sports, project “Ukrainian-British Scientific Diaspora”',
                siteTitle: 'Website',
                siteLink: 'https://academicuadiaspora.com',
                servicesText: 'Website creation.',
            },
            superHumans: {
                img: './img/superhumans/superhumans.png',
                text: `The 42 Foundation team developed landing pages for a major fundraising campaign as part of the Superhumans project. Among the partners who helped collect donations were companies like Mastercard and BlaBlaCar.`,
                partnerText: 'Superhumans',
                siteTitle: 'Website',
                siteLink: 'https://mastercard.superhumans.com/',
                servicesText: 'Creating landing pages for fundraising.'
            },
            economy: {
                img: './img/economy/economy.png',
                text: `The 42 Foundation team developed the official website for the government grant program "Own Business". Thanks to the initiative, Ukrainians can receive grants of up to 250 thousand hryvnias to create or expand their own business. More than 14 thousand Ukrainians have already used the micro and small business support program.`,
                partnerText: 'Ministry of Economy of Ukraine',
                siteTitle: 'Website',
                siteLink: 'https://vlasnaspravagrant.com.ua',
                servicesText: 'Website creation'
            },
            dila: {
                img: './img/dila/dila.png',
                text: `Our partners from the Dila Foundation approached 42 Foundation with a request to create branding, including logos for the foundation, taking into account the existing logo of the Dila company, but focusing on the fact that the foundation's activities are charitable projects.`,
                partnerText: 'Dila Foundation',
                siteTitle: '',
                siteLink: '',
                servicesText: 'Creating branding.'
            },
            unity: {
                img: './img/unity/unity.png',
                text: `The 42 Foundation team developed the name for the Unity Foundation. Additionally, we conducted a strategic session with our partners, after which our designers created a branding package, including the logo. Our team's programmers also developed the official website for Unity Foundation.`,
                partnerText: 'Unity Foundation',
                siteTitle: 'Website',
                siteLink: 'https://u.foundation/',
                servicesText: 'Creating the name, branding, official website, social media.'
            }
        },
        formSubmit: {
            title: "DOES YOUR ORGANIZATION NEED A RELIABLE WEBSITE WITH CREATIVE AND MODERN DESIGN?",
            text: "We have experience in creating reliable platforms of any complexity",
            form: {
                phone: "Your phone",
                    email: "Your email",
                    message: "Your message",
                    formBtn: "Submit",
                    success: "Application submitted!",
                    wait: "Wait...",
                }
            },
        pagesFound: {
            project: {
                            title: "ABOUT THE PROJECT",
                            text: "The Ukrainian-British Scientific Diaspora project was created at the initiative of the President of Ukraine's Fund for Education, Science, and Sports. The aim of the initiative is to identify scientists of Ukrainian origin in the United Kingdom in order to create a network of the academic community that will contribute their knowledge and expertise to the reconstruction of Ukraine.",
                            subtextImg: "President of Ukraine's Fund for Education, Science, and Sports",
                            text2: "Now, more than ever, Ukraine needs the strong support of experienced and proactive members of the academic community to defend against Russian aggression and to further promote the recovery and development of our country. We hope for successful collaboration, the implementation of ambitious projects, and initiatives in cooperation with British academic circles.",
                        },
            tasks: {
                            title: "Tasks of the Project",
                            tasksText1: "Unite the community of Ukrainian scientists in the United Kingdom",
                            tasksText2: "Gather researchers for the restoration of education and science in Ukraine",
                            tasksText3: "Establish connections between Ukrainian and British academia",
                        },
            goals: {
                            title: "Goals of the Project",
                            textArray: [
                                {
                                    text: "Initiate joint research projects between British and Ukrainian institutions;"
                                },
                                {
                                    text: "Prepare policy papers and research briefs for requests from government institutions;"
                                },
                                {
                                    text: "Supervise Ukrainian PhD students by British scientists and facilitate access to international research opportunities;"
                                },
                                {
                                    text: "Conduct seminars, guest lectures, and workshops at Ukrainian universities;"
                                },
                                {
                                    text: "Engage British scientists of Ukrainian origin in teaching at Ukrainian universities."
                                }
                            ]
                        },
            collaboration: {
                            title: "About Our Collaboration - Website Development",
                            collaborationText: "Our team's task was to develop the official website. The project is implemented by the President of Ukraine Foundation for the Support of Education, Science, and Sports. Therefore, one of the tasks was to use the existing branding. We integrated all necessary blocks into a single-page format - a landing page.",
                            designTitle: "UI/UX Design or Graphic Design",
                            designText: "The website design was created based on the existing branding and brand book of the President of Ukraine Foundation for the Support of Education, Science, and Sports. We used the color scheme, typography, shapes, and layout of elements on the page. As a result, the final product ensures a high level of UI/UX that aligns with the brand and its values.",
                            platformTitle: "ON WHICH PLATFORM THE SITE WAS DEVELOPED",
                            platformText: "The website was created on WordPress. This is one of the largest platforms for website creation. WP has an intuitive interface that makes it easy to add and edit information. WordPress is regularly updated to ensure the security of the website and protection against potential threats. </br></br> Based on the organization's brand book, a custom WP theme was developed. Therefore, we chose corporate colors, fonts, and other design elements to ensure brand consistency across all website elements.",
                            stackTitle: "Technology Stack",
                            stackText: "Considering the project architecture, site functionality, and system requirements, the following stack (set of technologies) was chosen:",
                            stackArray: [
                                {
                                    technology: `<span class="font-bold">Tailwind CSS</span> - an open-source framework that creates a list of CSS classes for styling each element.`
                                },
                                {
                                    technology: `<span class="font-bold">Alpine.js</span> – a lightweight JavaScript framework that, combined with TailwindCSS, allows all code, including visual markup and logic, to be written in HTML next to the relevant data blocks.`
                                }
                            ],
                            adminTitle: "Admin Panel",
                            adminText: `In the development of the site's software and administrative part, we used the classic WordPress admin panel model. </br></br> As a result, a convenient admin panel was created that allows the partner to easily and effectively manage the site: edit text, upload images, add content in different languages.`,
                            multilanguageTitle: "MULTILINGUALISM",
                            multilanguageText: `The site has two language versions: Ukrainian and English.</br></br> In this project, we opted out of automatic translation despite additional costs for professional content translation. </br></br> Automatic translation is only performed within the administrative panel, which is invisible to users.`
                        }
        },
        pageSuperhumans: {
            project: {
                title: "ABOUT THE PROJECT",
                text: "The 'Ukrainian-British Scientific Diaspora' project was initiated by the President of Ukraine's Foundation for the support of education, science, and sports, and supported by the 'Eidos' center. The goal of the project is to unite researchers who are ready to contribute their knowledge and experience to the rebuilding of Ukraine.",
                masterCard: "Mastercard",
                masterCardText: "As part of the 'UNITING FOR HELP' campaign, Mastercard initiated a fundraising drive for the Superhumans Center. Mastercard, in turn, matched every donation.",
                seoTitle: "SEO Superhumans",
                seoUserName: "Olga Rudneva",
                seoText: "Our task is not only to restore lost opportunities but also to help people who have been traumatized by the war find their superpowers. Here, our values align perfectly with Mastercard — more innovation, more opportunities. I am confident that together we can not only change the lives of individual people but also build a new world of superhumans. You are incredible. To create such an amazing resource in such a short time is something. I will be happy to continue supporting all your initiatives. I am immensely grateful for your dedication and achieving such a result.",
                BlaBlaCar: "BlaBlaCar",
                BlaBlaCarText: "BlaBlaCar raised nearly €60,000 in donations, which helped the Superhumans Center provide prosthetics to four Ukrainians. Additionally, BlaBlaCar has committed to contributing €50,000 to the Superhumans Center.",
                BlaBlaCarCommunications: "BlaBlaCar Communications Director CEE",
                BlaBlaCarUserName: "Yulia Krupenko",
                BlaBlaCarUserText: "In February 2023, BlaBlaCar and the Superhumans Center announced a collaboration, with 42 digital manufactory as our technology partner. We needed a landing page to inform BlaBlaCar users in 16 European countries about the international fundraising for prosthetics for Ukrainians affected by the war. Yevhen and his team developed the landing page concept and implemented it within weeks, staying in touch 24/7 during the launch and throughout the fundraising campaign, managing the process and making prompt adjustments. Thanks to 42 digital manufactory, we and Superhumans reached BlaBlaCar’s multimillion-member community and raised over €57,000 from users and the company. We continue our collaboration and efforts to help Ukraine and spread this message beyond its borders."
            },
            collaboration: {
                title: "About Our Collaboration - Website Development",
                collaborationText: "The main goal of the landing pages within this project is to raise funds to support and finance prosthetics and rehabilitation programs for people who have been injured in the war.",
                platformTitle: "PLATFORM ON WHICH THE SITE WAS DEVELOPED",
                platformText: "The websites were created on WordPress. This is one of the largest platforms for creating websites. WP has an intuitive interface that makes it easy to add and edit information. WordPress is regularly updated to ensure the security of the website and protect it from potential threats.",
                stackTitle: "Technology Stack",
                stackText: "Given the project's architecture, site functionality, and system requirements, the following technology stack was chosen:",
                stackArray: [
                    {
                        technology: "<span class='font-bold'>Tailwind CSS</span> - an open-source framework that creates a list of CSS classes for styling each element."
                    },
                    {
                        technology: "<span class='font-bold'>Alpine.js</span> - a lightweight JavaScript framework that, in combination with TailwindCSS, allows all code, including visual markup and logic, to be written in HTML next to the relevant data blocks."
                    },
                    {
                        technology: "<span class='font-bold'>Gulp</span> - a streaming build system that helps automate labor-intensive tasks in the development process. It is used to perform various frontend tasks such as running a web server, using preprocessors, and optimizing assets like CSS, JavaScript, and images."
                    }
                ],
                payTitle: "Payment System",
                payText: "One of the main tasks was to set up payment acceptance from those wishing to join the initiative. This was implemented using the Solidgate payment system. It is a platform for payment orchestration and processing that helps accept payments online. Through Solidgate's infrastructure, businesses can accept classic card payments, Apple Pay, and Google Pay.",
                adminTitle: "Admin Panel",
                adminText: "In the development of the program-administrative part of the site, we applied the classic WordPress admin panel model. </br></br> As a result, a convenient admin panel was created that allows the partner to easily and efficiently manage the site: edit text, upload images, and add content in different languages.",
                multilanguageTitle: "MULTILINGUAL SUPPORT",
                multilanguageText: "The website supports the following languages: Ukrainian, English, Polish, German, Spanish, Italian, Czech, French, Romanian, Slovak, Croatian, Hungarian, Belgian, Dutch, Serbian, and Portuguese. This allows us to reach a larger audience.</br></br> To ensure the multilingualism of the site, we used the Weglot Translate automatic translation plugin. It is a comprehensive translation plugin for WordPress, trusted by over 70,000 users worldwide.</br></br> Weglot Translate is fully optimized for multilingual SEO, with each translated page automatically indexed by Google. This allows us to greet millions of new visitors in several languages with high-quality translations involving artificial intelligence."
            }
        },
        pageEconomy: {
            project: {
                title: "ABOUT THE PROJECT",
                minEconomyText: "The 'Own Business' grant program is a component of the government project 'eWork,' launched in July 2022 to support micro and small businesses.",
                minEconomyText2: "The funds from the 'Own Business' program can be used for purchasing equipment, raw materials, paying rent, marketing and advertising services, commercial franchises, and leasing. Overall, the government invests 3.5 billion UAH in business development through such grants.</br></br> All opportunities of the program, conditions and algorithms for obtaining the grant, instructions for filling out the business plan, and current program results are now gathered on a dedicated landing page from the Ministry of Economy at [this link](https://vlasnaspravagrant.com.ua).",
                userTitle: "First Deputy Prime Minister of Ukraine – Minister of Economy of Ukraine",
                userName: "Yulia Svyrydenko",
                userText: "I am grateful to Ukrainian entrepreneurs who work legally, pay taxes, and thus support our economy and the military. We, in turn, will promote business development through government support programs.",
            },
            collaboration: {
                title: "About Our Collaboration - Website Development",
                collaborationText: "The task of 42 Foundation within this project was to create an interactive and informative website that contains all the necessary information for small businesses about available grants, including funding amounts, selection criteria, application deadlines, and other important information.",
                requirementsTitle: "Main Requirements for the Website",
                requirementsText: "Based on the brief, we highlighted the following criteria:",
                uiuxTitle: "UI/UX",
                uiuxText: "The site should be easy to use, with an intuitive design and navigation.",
                applicationsTitle: "Grant Applications",
                applicationsText: "The site should have a grant application form that is easy to use and includes all the necessary fields for collecting information.",
                securityTitle: "Security",
                securityText: "All personal data collected through the application form must be protected in accordance with Ukrainian data protection laws.",
                optimizationTitle: "Mobile Optimization",
                optimizationText: "The site should be optimized for viewing on mobile devices so users can easily access information and submit applications from any device.",
                seoTitle: "SEO",
                seoText: "The site should be optimized for search engines to be accessible to potential users.",
                analyticsTitle: "Analytics",
                analyticsText: "The site should have tools to track user visits and behavior to ensure continuous improvement and optimization.",
                designTitle: "UI/UX Design or Graphic Design",
                designText: "When developing the UI/UX design of the web page, we took into account several important aspects:",
                designArray: [
                    {
                        id: "1",
                        name: "Ease of Navigation",
                        text: "Navigation on the site should be intuitive. Menus should be logically structured, and links clearly marked."
                    },
                    {
                        id: "2",
                        name: "Clarity of Information",
                        text: "All information about grants should be presented clearly and understandably. It's important to use simple language and avoid jargon."
                    },
                    {
                        id: "3",
                        name: "Interactivity",
                        text: "The site should be interactive so users can easily interact with it. For example, include elements such as interactive application forms, user support chats, and other features."
                    },
                    {
                        id: "4",
                        name: "Graphic Design",
                        text: "The graphic design of the site should be attractive and professional. The use of colors, fonts, and images should be consistent throughout the site. Additionally, the design should reflect the brand and mission of the organization."
                    }
                ],
                platformTitle: "Platform on Which the Site Was Developed",
                platformText: "The website was created on WordPress. It is one of the largest platforms for website creation. WP has an intuitive interface that allows easy addition and editing of information. WordPress is regularly updated to ensure website security and protection against potential threats.</br></br> Based on the organization's brand book, a custom WP theme was developed. Thus, we chose corporate colors, fonts, and other design elements to ensure brand integrity across all website elements.",
                stackTitle: "Technology Stack",
                stackText: "Given the project architecture, site functionality, and system requirements, the following technology stack was chosen:",
                stackArray: [
                    {
                        technology: "<span class='font-bold'>Tailwind CSS</span> - an open-source framework that creates a list of CSS classes to style each element."
                    },
                    {
                        technology: "<span class='font-bold'>Alpine.js</span> - a lightweight JavaScript framework that, combined with TailwindCSS, allows all code, including visual markup and logic, to be written in HTML next to the corresponding data blocks."
                    },
                    {
                        technology: "<span class='font-bold'>Gulp</span> - a streaming build system that helps automate time-consuming tasks in the development process. It is used for various frontend tasks such as running a web server, using preprocessors, optimizing assets such as CSS, JavaScript, and images."
                    }
                ],
                adminTitle: "Admin Panel",
                adminText: "In developing the programmatic and administrative part of the site, we applied the classic WordPress admin panel model. </br></br> As a result, a convenient administrative panel was created, allowing the partner to easily and effectively manage the site: edit text, upload images, and add content in different languages."
            }

        },
        pageDila: {
            project: {
                title: "ABOUT THE PROJECT",
                text: "Dila Foundation implements charitable projects in the field of medicine and helps the Armed Forces of Ukraine. Health is a socially important area, so the foundation's mission is to support and strengthen the health of Ukrainians by providing necessary medical services and resources. One of the main types of assistance is laboratory diagnostics.",
                dilaTitle: 'INSTAGRAM DILA FOUNDATION:',
                imgText: `Our goal is to support the health of Ukrainians and contribute to building a successful future for our country.</br></br> We believe that the health and well-being of each person determine the development of the economy, culture, and the future of the country as a whole.`,
                text2: "Since the beginning of the full-scale invasion, Dila Foundation has directed 1,163,328 hryvnias to assist Ukrainian military personnel. We worked in three main areas: direct assistance to military personnel, assistance to military hospitals in Kyiv, Odesa, Dnipro, Kharkiv, and Lviv, and cooperation with charitable foundations."
            },
            collaboration: {
                title: "ABOUT OUR COLLABORATION - LOGO DESIGN",
                tasksTitle: "About the Tasks",
                tasksText: `The brand represents an organization that strives to make the world a better place through charity and projects focused on the well-being of Ukrainians. Therefore, it is important to create the right visual perception of the brand using specific elements.</br></br> The main task was to create a logo that would include design elements from the parent company while adding visual elements that reflect the charitable focus of the organization working in the medical field. </br></br> As for the identity, the task was to maintain the corporate colors but refresh and modernize some of them while preserving the brand's recognizability.`,
                userName: "LILIA Moiseeva",
                userPosition: "Graphic Designer, Art Director",
                userText: `A certain challenge, but always an interesting task, is working on projects with a parent organization. It is necessary to find the right form, invent something new while maintaining individuality and recognizability. It's a pleasure to work with charitable organizations because you understand your contribution to helping others.`,
                colorTitle: "Colors",
                colorText: `Regarding corporate identity, it was decided to use corporate colors with updates to some of them to maintain brand recognizability. The main color is blue. In certain usage cases, it can be complemented with an accent orange color. Accompanying colors are white and black.`,
                logoTitle: `Logo`,
                logoAfter: `After`,
                logoBefore: `Before`,
                logoText: `The charity foundation's logo was developed considering modern design trends, using soft lines to reflect the organization's humanitarian mission.</br></br> We developed a brand book with all necessary elements: main logo, secondary logo, and font logo.</br></br> The main logo has both a visual element and text. The visual part includes the letter D (the first letter of the organization's name - Dila) with a plus sign in the middle (medical field) and a hand element symbolizing charity. The visual form can be enclosed in a rectangle.</br></br> The secondary logo can be used alongside the main one, where visually motivated. The visual form can fit into a square.</br></br> The font logo is used only with the main ones as a supplementary element.`,
                elementTitle: `Identity Elements`,
                elementText: `The brand identity now includes six main recognizable elements that are used separately or in combination with each other.`,
                useTitle: `USE OF IDENTITY AND BRAND ELEMENTS`,
                useText: `In particular, the rules for the use of logos and colors are prescribed:`,
                useList: [
                    {
                        text: 'on different backgrounds,',
                    },
                    {
                        text: 'in black and white version,'
                    },
                    {
                        text: 'inverted version,'
                    },
                    {
                        text: 'sizes of all types of logos,'
                    },
                    {
                        text: 'safe zones (boundaries) of the logo that should not be violated,'
                    },
                    {
                        text: 'prohibited manipulations with logo rotation, tilting,'
                    },
                    {
                        text: 'correct use of colors, using such branding elements as patterns, accent line, etc.'
                    },
                ],
                socialTitle: `SOCIAL MEDIA`,
                socialText: `Since the foundation actively maintains social media, one of the tasks for the project graphic designer was to design visuals for social media. New elements for social media templates were developed according to the new identity. At the same time, all templates are practical to use.`,
            },
        },
        pageUnity: {
            project: {
                title: "ABOUT THE PROJECT",
                text: `Unity Foundation is a charitable organization focusing on three main areas:</br> 1.Assisting the Armed Forces of Ukraine.</br> 2.Supporting and developing sports in Ukraine.</br> 3.Helping children with disabilities and promoting inclusivity.`,
              },
            collaboration: {
                title: "About Our Collaboration - Website Development",
                text1: "The main purpose of the Unity Foundation website is to provide information about the foundation's mission, goals, and projects. It serves as a platform to engage donors, volunteers, and partners. The site also features news about the foundation's activities.",
                text2: "The tasks included creating a concise, minimalist, yet modern design. Colors, fonts, and other elements were aligned with the Unity Foundation's corporate identity. The site is easy to use and navigate, optimized for various devices: PCs, tablets, mobile phones, etc. The site complies with all security and privacy standards. All personal data collected through the site is protected.",
                platformTitle: "PLATFORM USED FOR THE WEBSITE",
                platformText: "The Shopify admin panel is an interface that allows site owners to conveniently manage content. It provides access to various functions and tools that help gather and analyze statistics.",
                stackTitle: "Technology Stack",
                stackText: "Given the project architecture, site functionality, and system requirements, the following technology stack was chosen:",
                stackArray: [
                    {
                        technology: "<span class='font-bold'>Tailwind CSS</span> - an open-source framework that creates a list of CSS classes for styling each element."
                    },
                    {
                        technology: "<span class='font-bold'>Alpine.js</span> - a lightweight JavaScript framework that, combined with TailwindCSS, allows all code, including visual markup and logic, to be written in HTML alongside the corresponding data blocks."
                    },
                    {
                        technology: "<span class='font-bold'>Gulp</span> - a streaming build system that helps automate tedious tasks in the development process. It's used for various frontend tasks such as running a web server, using preprocessors, and optimizing assets like CSS, JavaScript, and images."
                    }
                ],
                adminTitle: "Admin Panel",
                adminText: "The site’s admin panel allows managing all aspects of the website in one place. It provides tools for adding and editing content, managing the site structure, and setting access rights. Thus, the admin panel simplifies the site management process, making it quick and convenient.",
                multilanguageTitle: "MULTILINGUAL SUPPORT",
                multilanguageText: "The site has two language versions: Ukrainian and English.</br></br> In this project, we opted out of automatic translation despite the additional costs of professional content translation.</br></br> Automatic translation is only carried out within the admin panel, invisible to users."
            }
        },
    },
    ua: {
        homepage: {
            header: {
                navItems: [
                    {
                        text: "діяльність",
                        href: "#our-work"
                    },
                    {
                        text: "партнери",
                        href: "#partners"
                    },
                    {
                        text: "ЗАСНОВНИКИ",
                        href: "#our-team"
                    },
                    {
                        text: "КОНТАКТИ",
                        href: "#footer"
                    },
                ],
                donationBtn: "Допомогти",
            },
            hero: {
                titleSlide1: "Сайт для проєкту Фонду Президента України - Українсько-Британська наукова діаспора",
                titleSlide2: "ЛендінгИ для проєктів superhumans з mastercard та blablacar",
                titleSlide3: "Сайт для грантової програми 'Власна справа' - проєкту Мінекономіки України",
                titleSlide4: "Сайт та айдентика для благодійного фонду Unity Foundation",
                titleSlide5: "Айдентика для Dila Foundation",
                donationBtn: "детальніше"
            },
            factsAndFigures: {
                title: "ФАКТИ І ЦИФРИ",
                sectionDescription: "<span class=\"font-bold\">42 Foundation</span> покладається на пожертви від приватних осіб, корпорацій та урядів для виконання наших місій. <span class=\"hidden md:inline\"><br></span> Ось деякі факти та цифри, які висвітлюють нашу діяльність:",
                firstBlockTitleText: "",
                firstBlockDescription: "Кількість годин роботи команди",
                secondBlockTitleNumber: "14",
                secondBlockTitleText: "",
                secondBlockDescription: "Кількість проєктів, яким надається підтримка",
                thirdBlockTitleNumber: "3",
                thirdBlockTitleText: "funds",
                thirdBlockDescription: "Total volume of help"
            },
            ourTeam: {
                opportunityTeamSubtitle: "Засновники",
                teamSlides: [
                    {
                        img: "./img/main-page/ruslan-aliev3.png",
                        name: "РУСЛАН АЛІЄВ",
                        position: "Засновник",
                        nextDoorLink: "mailto:aliiev@42charity.foundation",
                        facebookLink: "https://www.facebook.com/Ruslan.Aliiev.91",
                        linkedInLink: "https://linkedin.com/in/ruslan-aliiev",
                        instagramLink: "#",
                        twitterLink: "#"
                    },
                    {
                        img: "./img/main-page/dim-zrazhevsk2.png",
                        name: "Дім ЗАРАЖЕВСЬКИЙ",
                        position: "Співзасновник",
                        nextDoorLink: "mailto:zarazhevskyi@42charity.foundation",
                        facebookLink: "https://www.facebook.com/Zarazhevsky/",
                        linkedInLink: "https://www.linkedin.com/in/dim-zarazhevsky-3a0963159/",
                        instagramLink: "https://www.instagram.com/zarazhevskyi/",
                        twitterLink: "#"
                    },
                    {
                        img: "./img/main-page/maksim-petrenko.png",
                        name: "МАКСИМ ПЕТРЕНКО",
                        position: "Співзасновник",
                        nextDoorLink: "mailto:petrenko@42charity.foundation",
                        facebookLink: "#",
                        linkedInLink: "https://www.linkedin.com/in/maks-petrenko-9640aa28b/",
                        instagramLink: "#",
                        twitterLink: "#"
                    }
                ]
            },
            ourWork: {
                title: "НАША ДІЯЛЬНІСТЬ",
                swiperSlides: [
                    {
                        img: "./img/main-page/our-work-1.png",
                        textCard: "логотипи та айдентика",
                    },
                    {
                        img: "./img/main-page/our-work-2.png",
                        textCard: "сайти та онлайн продукти",
                    },
                    {
                        img: "./img/main-page/our-work-3.png",
                        textCard: "стратегічне плануванняи",
                    }
                ]
            },
            banner: {
                sectionTitle: "Робити гарні справи разом",
                title: "Хочу допомогти",
                textBtn: "Подати заявку"
            },
            partners: {
                title: "Наші партнери"
            },
            case: {
                title: "Кейси",
                array: [
                    {
                        img: "./img/case/case-1.png",
                        status: "Активний",
                        statusBg: "bg-malibu",
                        name: "сайт для ПРОєКТУ Фонду Президента України",
                        description: "42 Foundation завжди підтримує державні ініціативи.",
                        btn: "Детальніше",
                        href: "/",
                    },
                    {
                        img: "./img/case/case-2.png",
                        status: "Завершений",
                        statusBg: "bg-darkGray",
                        name: "PRO BONO ЛЕНДИНГИ для superhumans ",
                        description: "Команда 42 Foundation розробила pro bono лендінги для великої кампанії зі збору коштів Superhumans.",
                        btn: "Детальніше",
                        href: "/",
                    },
                    {
                        img: "./img/case/case-3.png",
                        status: "Активний",
                        statusBg: "bg-malibu",
                        name: "Сайт урядовОЇ грантовОЇ програмИ «Власна справа»",
                        description: "Команда розробила офіційний Сайт урядової грантової програми “Власна справа”.",
                        btn: "Детальніше",
                        href: "/",
                    },
                ],
            },
            donation: {
                title: "Допомогти донатом",
                card: {
                    title: "Картка",
                    description: "Ми безпосередньо не обробляємо і не зберігаємо дані вашої картки.",
                    yourAmountPlaceholder: "Ваша сума",
                    oneTimeText: "Одноразово",
                    regularText: "Регулярно",
                    conversion: "Остаточна сума, що стягується, може коливатися через конвертацію пожерт- ви з вашої валюти в українську гривню. У разі виникнення будь-яких питань чи запитів, будь ласка, зв'яжіться з нами за адресою ceo@42charity.foundation.",
                    cardCharge: "Списання з вашої картки може відбуватися в гривнях. 1USD ~ 37 ГРН",
                    privacyAgreement: 'Я ознайомився/лась з <a href="/public-offer.html" class="text-darkGray underline underline-offset-1">Публічною офертою</a> щодо надання благодійної пожертви, <a href="/terms-and-conditions.html" class="text-darkGray underline underline-offset-1">Умовами використання сайту</a>  та погоджуюся з тим, що введена мною особиста інформація буде використана згідно з <a href="/privacy-policy.html" class="text-darkGray underline underline-offset-1">Політикою приватності Фонду</a>.',
                    privacyAgreementLink: "Умовами надання послуг",
                    requiredError: "Це поле є обов'язковим!",
                    donateBtn: "ЗРОБИТИ ВНЕСОК"
                },
                bankTransfer: {
                    title: "Банківський переказ",
                    beneficiary: {
                        name: "Бенефіціар:",
                        value: "БО \"БФ \"ФУНДАЦІЯ 42\""
                    },
                    EDPNOU: {
                        name: "ЄДРПОУ:",
                        value: "44980762"
                    },
                    bankBeneficiary: {
                        name: "Банк бенефіціара:",
                        value: "АТ «А–БАНК»"
                    },
                    accounts: [
                        {
                            name: "Account # / IBAN: (UAH)",
                            value: "UA023077700000026002411155643"
                        },
                        {
                            name: "Account # / IBAN: (USD)",
                            value: "UA203077700000026001411155644"
                        },
                        {
                            name: "Account # / IBAN: (EUR)",
                            value: "UA383077700000026000411155645"
                        }
                    ],
                    swiftCode: {
                        name: "Swift Code:",
                        value: "UKCBUAUK"
                    }
                },
                payPal: {
                    title: "PayPal",
                    btn: "СПЛАТИТИ З PAYPAL"
                },
                cryptocurrency: {
                    title: "Криптовалюта",
                    items: [
                        {
                            name: "Bitcoin",
                            value: "bc1qkp67nkqagz6gqe0plvkskzh598kkdt560v3fne"
                        },
                        {
                            name: "Ethereum",
                            value: "0xAb9c934DA77Da0F753C7aC690a06b80B33bcdce6"
                        },
                        {
                            name: "USDT",
                            value: "TT1XunzkHpqtW7JCswVWaoh2CcQpRyryAv"
                        }
                    ]
                }
            },
            footer: {
                footerTopTitle: "Сайт розроблено командою",
                contacts: {
                    title: "Контакти",
                    address: `вул. Івана Франка, буд. 22/24, </br> Шевченківський район, м. Київ, Україна, 01054`,
                },
                mapsWebsite: {
                    title: "Карта сайту",
                    mapsLink: [
                        {
                            textLink: "Діяльність",
                            href: "#our-work",
                        },
                        {
                            textLink: "Партнери",
                            href: "#partners",
                        },
                        {
                            textLink: "Засновники",
                            href: "#our-team",
                        },
                        {
                            textLink: "Робити гарні справи разом",
                            href: "#banner",
                        },
                        {
                            textLink: "ЗМІ про нас",
                            href: "#",
                        },
                        {
                            textLink: "Допомогти донатом",
                            href: "#donation",
                        },
                        {
                            textLink: "Новини",
                            href: "#",
                        },
                    ]
                },
                links: {
                    title: "Посилання",
                    linksLink: [
                        {
                            textLink: "Політика Фонду",
                            href: "./privacy-policy.html",
                        },
                        {
                            textLink: "Публічна оферта",
                            href: "./public-offer.html",
                        },
                        {
                            textLink: "Умови використання сайту",
                            href: "./terms-and-conditions.html",
                        },
                        {
                            textLink: "Банківські реквізити",
                            href: "#",
                        },
                    ]
                },
                privacyItems: [
                    {
                        name: "Умови використання сайту",
                        href: "/terms-and-conditions.html"
                    },
                    {
                        name: "Політика приватності Фонду",
                        href: "/privacy-policy.html"
                    },
                ],
                copyright: "42 Foundation © 2023"
            }
        },
        mediaAbout: {
            title: "ЗМІ про проєкт",
            homepage: [
                {
                    img: "./img/media-about/forbes.png",
                    name: "Мінекономіки запустило сайт грантової програми «Власна справа»",
                    description: "Мінекономіки запустило сайт про урядову грантову програму «Власна справа», яка діє в межах проєкту «єРобота». Про це повідомила пресслужба міністерства...",
                    btn: "Детальніше",
                    href: "https://forbes.ua/news/minekonomiki-zapustilo-sayt-grantovoi-programi-vlasna-sprava-07032024-19709",
                },
                {
                    img: "./img/media-about/mind.png",
                    name: "Мінекономіки запустило офіційний сайт грантової програми «Власна справа»",
                    description: "Відтепер усі можливості програми, умови й алгоритми отримання гранту, інструкції для заповнення бізнес-плану та поточні результати програми зібрані на окремій лендінг-сторінці...",
                    btn: "Детальніше",
                    href: "https://mind.ua/news/20270614-minekonomiki-zapustilo-oficijnij-sajt-grantovoyi-programi-vlasna-sprava",
                },
            ],
            economy: [
                {
                    img: "./img/media-about/forbes.png",
                    name: "Мінекономіки запустило сайт грантової програми «Власна справа»",
                    description: "Мінекономіки запустило сайт про урядову грантову програму «Власна справа», яка діє в межах проєкту «єРобота». Про це повідомила пресслужба міністерства...",
                    btn: "Детальніше",
                    href: "https://forbes.ua/news/minekonomiki-zapustilo-sayt-grantovoi-programi-vlasna-sprava-07032024-19709",
                },
                {
                    img: "./img/media-about/mind.png",
                    name: "Мінекономіки запустило офіційний сайт грантової програми «Власна справа»",
                    description: "Відтепер усі можливості програми, умови й алгоритми отримання гранту, інструкції для заповнення бізнес-плану та поточні результати програми зібрані на окремій лендінг-сторінці...",
                    btn: "Детальніше",
                    href: "https://mind.ua/news/20270614-minekonomiki-zapustilo-oficijnij-sajt-grantovoyi-programi-vlasna-sprava",
                },
            ],
        },
        currentPage: {
            home: "Головна",
            case: "Кейси",
            found: "Сайт для проєкту Фонду Президента України - \"Україно-британська наукова діаспора\"",
            economy: "Сайт урядової грантової програми «Власна справа»",
            superHumans: "Лендінги для проєктів Superhumans з Mastercard та BlaBlaCar",
            dila: "Ребрендинг для Dila Foundation",
            unity: "Розробка сайту та айдентики для Unity Foundation",
        },
        pageHero: {
            statusActive: "Активний проєкт",
            statusDone: "Завершений проєкт",
            found: `САЙТ ДЛЯ ПРОЄКТУ </br> ФОНДУ ПРЕЗИДЕНТА УКРАЇНИ - </br> <span class="text-malibu">УКРАЇНСЬКО-БРИТАНСЬКА НАУКОВА ДІАСПОРА</span>`,
            superHumans: `ЛендінгИ </br> для проєктів superhumans з <span class="text-malibu">mastercard</span> та <span class="text-malibu">blablacar</span>`,
            economy: `Сайт урядовОЇ </br> грантовОЇ програмИ <span class="text-malibu">«Власна справа»</span>`,
            dila: `Ребрендинг для </br> <span class="text-malibu">Dila Foundation</span>`,
            unity: `РОЗРОБКА САЙТУ ТА АЙДЕНТИКИ ДЛЯ <span class="text-malibu">unity FOUNDATION</span>`
        },
        sliderTeamPage: {
            found: {
                title: 'Сайт розроблено командою',
                users: [
                    {
                        img: "./img/42-team/olena-moiseinko.png",
                        name: "Олена Моісеїнко",
                        position: "Проджект менеджерка",
                    },
                    {
                        img: "./img/42-team/tanya-kubyk.png",
                        name: "Тетяна Кубик",
                        position: "UI/UX дизайнер",
                    },
                    {
                        img: "./img/42-team/diana-bidshehi.png",
                        name: "Діана Бідшехі",
                        position: "UI/UX дизайнер",
                    },
                    {
                        img: "./img/42-team/maksym-pianchuk.png",
                        name: "Максим П’янчук",
                        position: "Front-end розробник",
                    },
                    {
                        img: "./img/42-team/roman-hado.png",
                        name: "Роман Хадо",
                        position: "Front-end розробник",
                    },
                ],
            },
            unity: {
                title: 'Сайт розроблено командою',
                users: [
                    {
                        img: "./img/42-team/olena-moiseinko.png",
                        name: "Олена Моісеїнко",
                        position: "Проджект менеджерка",
                    },
                    {
                        img: "./img/42-team/diana-bidshehi.png",
                        name: "Діана Бідшехі",
                        position: "UI/UX дизайнер",
                    },
                    {
                        img: "./img/42-team/maksym-pianchuk.png",
                        name: "Максим П’янчук",
                        position: "Front-end розробник",
                    },
                    {
                        img: "./img/42-team/roman-hado.png",
                        name: "Роман Хадо",
                        position: "Front-end розробник",
                    },
                ],
            },
            dila: {
                title: 'ДИЗАЙНЕР ПРОЄКТУ',
                users: [
                    {
                        img: "./img/42-team/lilia-moisyeva.png",
                        name: "ЛІЛІЯ МОІСЄЄВА",
                        position: "Графічний дизайнер, арт директор",
                    },
                ]
            },
        },
        projectSupport: {
            title: 'ПРО ДОПОМОГУ ПРОЄКТУ',
            userName: "Руслан Алієв",
            userPosition: "IT-підприємець, засновник 42 GROUP",
            found: {
                description: `Для нас важливо мати можливість обʼєднати спільноту українських науковців закордоном, зібрати дослідників для відбудови освіти та науки в Україні. </br> Проєкт покликаний сприяти відбудові та розвитку України, а також інтегрувати українських учених до світової наукової спільноти. </br> Я, з огляду на досвід викладацької діяльності, впевнений, що такі ініціативи дають точки зросту для науковців та відкривають нові горизонти.`,
            },
            dila: {
                description: `Майбутнє держави - здорова нація. Вважаю, що організації, які не лише працюють у сфері медицини, а й займаються благодійністю у цьому напрямку - роблять надважливу справу. Dila Foundation за сприяння 42 Foundation відкрила програму для військовослужбовців Збройних Сил України. Наші захисники можуть безоплатно обстежуватись у рамках ініціативи, що значно полегшує діагностику здоровʼя та підтримку гарного самопочуття або скорочує час на передопераційну підготовку, оскільки послуги у мережі лабораторій надаються у найшвидший термін. Пишаємось партнерством та раді можливості створювати такі проєкти разом.`,
            },
            unity: {
                description: `Unity Foundation - це спільнота людей, які вірять у важливість допомоги. Вони не просто партнери у звичному розумінні цього слова. Вони - частина нашої великої родини однодумців, котрі заряджені на допомогу тим, хто її потребує.<br><br> Ми віримо, що кожна людина заслуговує на можливість жити повноцінним життям, незалежно від їх обставин. Отже, ми працюємо кожного дня, щоб зробити світ кращим місцем для всіх нас.`,
            },
            economy: {
                description: `Розробка веб-сайту для Міністерства економіки України - це не просто технічне завдання зі створення платформи для інформації, ми створюємо місток між урядом та громадянами, місток, який забезпечує доступність інформації та взаємодію. Створення діджитал платформ є одним із ключових напрямкомів роботи 42 Foundation. Кейс із запуском сайту для грантової програми, направленої на підтримку бізнесу в Україні - це можливість опосередковано допомогти підприємцям в нашій країні у цей складний час. Доступніша інформація = більше шансів на отримання допомоги.`
            },
            superHumans: {
                description: `Superhumans - це проєкт, ціль якого абсолютно точно співпадає з цінностями та одним із ключових напрямків роботи 42 Foundation - це доступні медичні послуги для постраждалих внаслідок війни. Допомогти співвітчизникам віднайти свою суперсилу - потужна ідея. У проєкту є низка донорів для реалізації усіх напрямків, а наша команда підтримує діджитал частину проєкту, оскільки це саме те, що ми вміємо робити найкраще.`
            }
        },
        info: {
            infoTitle: 'Інформація',
            partnerTitle: 'Партнер',
            servicesTitle: 'Послуги',
            found: {
                img: './img/found/found.png',
                text: `42 Foundation завжди підтримує державні ініціативи. Нашим партнером став Фонд Президента України з підтримки освіти, науки та спорту. Ми співпрацювали у рамках проєкту Українсько-британська наукова діаспора. Метою ініціативи є створення мережі академічної спільноти, яка готова вкладати свої знання та експертизу для відбудови України. </br> Це мережа українських дослідників у Великій Британії, об\'єднаних для розвитку науки в Україні. Завданням нашої команди було розробити Офіційний сайт Українсько-Британської наукової діаспори.`,
                partnerText: 'Фонд Президента України з підтримки освіти, науки та спорту, проєкт “Українсько-британська наукова діаспора”',
                siteTitle: 'Сайт',
                siteLink: 'https://academicuadiaspora.com',
                servicesText: 'Створення сайту.',
            },
            superHumans: {
                img: './img/superhumans/superhumans.png',
                text: `Команда 42 Foundation розробила лендінги для великої кампанії зі збору коштів у рамках проєкту Superhumans. Серед партнерів, які допомагали збирати донати були такі компанії як Mastercard та BlaBlaCar.`,
                partnerText: 'Superhumans',
                siteTitle: 'Сайт',
                siteLink: 'https://mastercard.superhumans.com/',
                servicesText: 'Створення лендінгів для збору коштів.',
            },
            economy: {
                img: './img/economy/economy.png',
                text: `Команда 42 Foundation розробила офіційний Сайт урядової грантової програми “Власна справа”. Завдяки ініціативі українці можуть отримати гранти до 250 тисяч гривень для створення чи розширення власного бізнесу. Програмою підтримки мікро- та малого бізнесу скористались вже понад 14 тисяч українців. `,
                partnerText: 'Міністерство економіки України',
                siteTitle: 'Сайт',
                siteLink: 'https://vlasnaspravagrant.com.ua',
                servicesText: 'Створення сайту',
            },
            dila: {
                img: './img/dila/dila.png',
                text: `Наші партнери з Dila Foundation звернулась до 42 Foundation із запитом створити айдентику, зокрема, логотипи для фундації з урахуванням уже наявного логотипа компанії Dila, але з фокусом на те, що діяльність фонду - благодійні проєкти.`,
                partnerText: 'Dila Foundation',
                siteTitle: '',
                siteLink: '',
                servicesText: 'Створення айдентики.',
            },
            unity: {
                img: './img/unity/unity.png',
                text: `Команда 42 Foundation розробила назву фундації Unity Foundation. Також, ми разом з партнерами провели стратегічну сесію, за результатами якої наші дизайнери розробили пакет айдентики, який включає логотип. Також, програмісти нашої команди створили офіційний сайт для Unity Foundation.`,
                partnerText: 'Unity Foundation',
                siteTitle: 'Сайт',
                siteLink: 'https://u.foundation/',
                servicesText: 'Створення назви, айдентики, офіційного сайту, соціальних мереж.',
            },
        },
        formSubmit: {
            title: "ВАША ОРГАНІЗАЦІЯ ПОТРЕБУЄ НАДІЙНИЙ САЙТ З КРЕАТИВНИМ СУЧАСНИМ ДИЗАЙНОМ?",
            text: "Ми маємо досвід зі створення надійних платформ будь-якої складності",
            form: {
                phone: "Ваш телефон",
                email: "Ваш e-mail",
                message: "Ваше повідомлення",
                formBtn: "Подати заявку",
                success: "Заявка відправлена!",
                wait: "Зачекайте...",
            }
        },
        pagesFound: {
            project: {
                title: "ПРО ПРОЄКТ",
                text: "Проєкт Українсько-британська наукова діаспора створений за ініціативою Фонду Президента України з підтримки освіти, науки та спорту. Мета ініціативи - ідентифікувати науковців українського походження у Сполученому Королівстві задля створення мережі академічної спільноти, яка вкладатиме свої знання та експертизу для відбудови України.",
                subtextImg: "Фонд Президента України з підтримки освіти, науки та спорту",
                text2: "Саме зараз Україна, більше, ніж будь-коли раніше, потребує міцної підтримки досвідчених та проактивних представників академічної спільноти для захисту від російської агресії та подальшого сприяння відновленню та розвитку нашої держави. Вболіваємо за успішну спільну роботу, реалізацію амбітних проєктів та ініціатив у співпраці з британськими академічними колами.",
            },
            tasks: {
                title: "Задачі проєкту",
                tasksText1: "Об'єднати спільноту українських науковців у Великій Британії",
                tasksText2: "Зібрати дослідників для відбудови освіти та науки в Україні",
                tasksText3: "Об'єднати зв'язки між українською та британською академією",
            },
            goals: {
                title: "Цілі проєкту",
                textArray: [
                    {
                        text: "Ініціювання спільних дослідницьких проєктів між британськими та українськими інституціями;"
                    },
                    {
                        text: "Підготовка policy papers та дослідницьких брифів для запитів урядових інституцій;"
                    },
                    {
                        text: "Супервізія українських PHD студентів британськими науковцями та сприяння у доступі до міжнародних дослідницьких можливостей;"
                    },
                    {
                        text: "Проведення семінарів, гостьових лекцій, воркшопів в українських університетах;"
                    },
                    {
                        text: "Залучення британських науковців українського походження до викладання в українських університетах.",
                    }
                ]
            },
            collaboration: {
                title: "Про нашу співпрацю - розробка сайту",
                collaborationText: "Завданням нашої команди було розробити офіційний сайт. Проєкт втілює Фонд Президента України з підтримки освіти, науки та спорту. Тож, однією із задач було використання вже наявної айдентики. Усі необхідні блоки ми умістили в односторінковий формат - лендінг.",
                designTitle: "UI/UX дизайн або графічний дизайн",
                designText: "Дизайн вебсайту створений на базі готової айдентики та брендбуку Фонду Президента України з підтримки освіти, науки та спорту. Ми використали колір, шрифт, форму та розміщення елементів на сторінці. В результаті кінцевий продукт забезпечує високий рівень UI/UX, що відповідає бренду та його цінностям.",
                platformTitle: "НА ЯКІЙ ПЛАТФОРМІ РОЗРОБЛЕНО САЙТ",
                platformText: `Вебсайт було створено на WordPress. Це одна з найбільших платформ для створення вебсайтів. WP має інтуїтивно зрозумілий інтерфейс, який дозволяє легко додавати та редагувати інформацію. WordPress регулярно оновлюється для забезпечення безпеки вебсайту та захисту від потенційних загроз. </br></br> На основі брендбуку організації була розроблена іднивідуальна тема WP. Тож, ми обрали корпоративні кольори, шрифти та інші елементи дизайну, щоб забезпечити цілісність айдентики у всіх елементах вебсайту.`,
                stackTitle: "Стек технологій",
                stackText: "Зважаючи на архітектуру проєкту, функціональність сайту та системні вимоги, був обраний наступний стек (набір технологій):",
                stackArray: [
                    {
                        technology: `<span class="font-bold">Tailwind CSS</span> - фреймворк з відкритим вихідним кодом, який створює список CSS-класів для стилізації кожного елемента.`
                    },
                    {
                        technology: `<span class="font-bold">Alpine.js</span> – це легкий JavaScript-фреймворк, який у поєднанні з TailwindCSS дозволяє весь код, включаючи візуальну розмітку та логіку, прописувати в HTML поруч із відповідними блоками даних.`
                    },
                ],
                adminTitle: "Адміністративна панель",
                adminText: `В розробці програмно-адміністративної частини сайту ми застосували класичну модель WordPress admin panel. </br></br> В результаті було створено зручну адміністративну панель, яка дозволяє партнеру легко та ефективно керувати сайтом: редагувати текст, завантажувати зображення, додавати контент на різних мовах.`,
                multilanguageTitle: "МУЛЬТИМОВНІСТЬ",
                multilanguageText: `Сайт має дві мовні версії: українську та англійську.</br></br> У цьому проєкті ми відмовилися від автоматичного перекладу попри додаткові витрати на професійний переклад контенту. </br></br> Автоматичний переклад здійснюється лише в межах адміністративної панелі, невидимої користувачам.`,
            },
        },
        pageSuperhumans: {
            project: {
                title: "ПРО ПРОЄКТ",
                text: "Проект «Українсько-британська наукова діаспора» створений за ініціативою Фонду Президента України з підтримки освіти, науки і спорту та за підтримки центру \"Ейдос\". Мета проекту -  об’єднати дослідників, які готові вкладати свої знання та досвід у відбудову України. ",
                masterCard: "Mastercard",
                masterCardText: "Mastercard у рамках акції «ЄДНАТИСЯ ДЛЯ ДОПОМОГИ» ініціювало благодійний збір коштів для Superhumans Center. А Mastercard зі свого боку подвоїв кожен донат.",
                seoTitle: "СЕО Superhumans",
                seoUserName: "Ольга Руднєва",
                seoText: `Наша задача — не просто повертати людям втрачені можливості, ми прагнемо допомогти людям, які зазнали травм під час війни, знайти свої суперсили. І тут наші цінності повністю збігаються з Mastercard — більше інновацій, більше можливостей. Впевнена, разом ми зможемо змінити не лише життя конкретних людей, ми зможемо побудувати новий світ суперлюдей».</br></br> Ви неймовірні. За такий короткий термін зробити такий крутий ресурс, це щось. Буду щаслива підтримувати й надалі всі ваші ініціативи. Я мега вдячна вам, що взялися і довели це до такого результату.`,
                BlaBlaCar: "BlaBlaCar",
                BlaBlaCarText: `BlaBlaCar зібрав майже €60 тис. донатів, завдяки яким Superhumans Center допоміг чотирьом українцям отримати протези.</br></br> Крім того, BlaBlaCar вже взяв на себе зобов'язання зробити внесок у розмірі 50 000 євро для Superhumans.`,
                BlaBlaCarCommunications: "BlaBlaCar Communications Director CEE",
                BlaBlaCarUserName: "Юлія крупенко",
                BlaBlaCarUserText: `У лютому 2023 BlaBlaCar та Superhumans Center оголосили про колаборацію, а команда 42 digital manufactory стала нашим технологічним партнером. Нам потрібен був лендінг, який розповість користувачам BlaBlaCar у 16 країнах Європи про міжнародний фандрейзинг на протезування українців, що постраждали під час війни.</br></br> Євген разом зі своєю командою розробили концепцію лендінгу та реалізували її за лічені тижні, а під час запуску та протягом всієї фандрейзингової кампанії були на звʼязку 24/7, керували процесом та оперативно впроваджували зміни.</br></br> Завдяки 42 digital manufactory ми з Superhumans розповіли про фандрейзинг багатомільйонній спільноті BlaBlaCar і залучили понад 57 000 євро від користувачів та компанії. Ми продовжуємо співпрацю, а також продовжуємо допомагати Україні та поширювати цей меседж поза її межами.`,
            },
            collaboration: {
                title: "про нашу співпрацю - розробка сайту",
                collaborationText: "Основна задача лендінгів у рамках цього проєкту - збір донатів для забезпечення підтримки та фінансування програм протезування та реабілітації для людей, які зазнали травм через війну.",
                platformTitle: "НА ЯКІЙ ПЛАТФОРМІ РОЗРОБЛЕНО САЙТ",
                platformText: `Вебсайти було створено на WordPress. Це одна з найбільших платформ для створення вебсайтів. WP має інтуїтивно зрозумілий інтерфейс, який дозволяє легко додавати та редагувати інформацію. WordPress регулярно оновлюється для забезпечення безпеки вебсайту та захисту від потенційних загроз.`,
                stackTitle: "Стек технологій",
                stackText: "Зважаючи на архітектуру проєкту, функціональність сайту та системні вимоги, був обраний наступний стек (набір технологій):",
                stackArray: [
                    {
                        technology: `<span class="font-bold">Tailwind CSS</span> - фреймворк з відкритим вихідним кодом, який створює список CSS-класів для стилізації кожного елемента.`
                    },
                    {
                        technology: `<span class="font-bold">Alpine.js</span> – це легкий JavaScript-фреймворк, який у поєднанні з TailwindCSS дозволяє весь код, включаючи візуальну розмітку та логіку, прописувати в HTML поруч із відповідними блоками даних.`
                    },
                    {
                        technology: `<span class="font-bold">Gulp</span> - це потокова система збірки, яка допомагає автоматизувати трудомісткі завдання у  розробницькому процесі. Він використовується для виконання різних завдань на фронтенді, таких як запуск вебсервера, використання препроцесорів, оптимізація активів, таких як CSS, JavaScript та зображення.`
                    }
                ],
                payTitle: "Платіжна система",
                payText: "Одним із головних завдань було налаштування приймання платежів від охочих долучитися до ініціативи. Реалізувати це було вирішено за допомогою платіжної системи Solidgate. Це платформа для оркестрації та процесингу платежів, що допомагає приймати платежі онлайн. Через інфраструктуру Solidgate бізнес може приймати класичні платежі карткою, Apple Pay та Google Pay.",
                adminTitle: "Адміністративна панель",
                adminText: `В розробці програмно-адміністративної частини сайту ми застосували класичну модель WordPress admin panel. </br></br> В результаті було створено зручну адміністративну панель, яка дозволяє партнеру легко та ефективно керувати сайтом: редагувати текст, завантажувати зображення, додавати контент на різних мовах.`,
                multilanguageTitle: "МУЛЬТИМОВНІСТЬ",
                multilanguageText: `Вебсайт підтримує такі мови: українську, англійську, польську, німецьку, іспанську, італійську, чеську, французьку, румунську, словацьку, хорватську, угорську, бельгійську, нідерландську, сербську та португальську. Це дозволяє охопити більшу аудиторію.</br></br> Для забезпечення мультимовності сайту ми використовували плагін автоматичного перекладу Weglot Translate. Це комплексний плагін для перекладу WordPress, якому довіряють понад 70 000 користувачів у всьому світі.</br></br>Weglot Translate повністю оптимізовано для багатомовного SEO, при цьому кожна перекладена сторінка автоматично індексується Google. Це дає нам змогу привітатися кількома мовами з мільйонами нових відвідувачів за допомогою високоякісних перекладів із залученням штучного інтелекту.`,
            },
        },
        pageEconomy: {
            project: {
                title: "ПРО ПРОЄКТ",
                minEconomyText: "Грантова програма «Власна справа» – компонент урядового проєкту «єРобота», що стартувала в липні 2022 року для підтримки мікро- та малого бізнесу. ",
                minEconomyText2: `Кошти за програмою «Власна справа» можна витратити на придбання обладнання, закупівлю сировини, сплату оренди, послуги маркетингу та реклами, комерційної концесії, лізингу. Загалом, Держава інвестує в розвиток бізнесу через подібні гранти 3,5 млрд грн.</br></br> Відтепер усі можливості програми, умови й алгоритми отримання гранту, інструкції для заповнення бізнес-плану та поточні результати програми зібрані на окремій лендінг-сторінці від Мінекономіки за посиланням https://vlasnaspravagrant.com.ua.`,
                userTitle: "Перший віцепрем’єр-міністр України – Міністр економіки України",
                userName: "Юлія Свириденко",
                userText: `Я вдячна українським підприємцям, які працюють легально, платять податки і збори й таким чином підтримують нашу економіку та військо. Ми ж зі своєї сторони сприятимемо розвитку бізнесу через урядові програми підтримки.`,
            },
            collaboration: {
                title: "про нашу співпрацю - розробка сайту",
                collaborationText: "Задачею 42 Foundation у рамках цього проєкту було створити інтерактивний та інформативний вебсайт, який містить усю необхідну інформацію для малого бізнесу про доступні гранти, включаючи суму фінансування, критерії відбору, терміни подання заявок та іншу важливу інформацію.",
                requirementsTitle: "Основні вимоги до вебсайту",
                requirementsText: "За підсумками брифу ми виокремили наступні критерії:",
                uiuxTitle: "Ui/ux",
                uiuxText: "Сайт має бути простим у використанні, з інтуїтивно зрозумілим дизайном та навігацією. ",
                applicationsTitle: "Подання заявок",
                applicationsText: "На сайті має бути форма для подання заявок на грант, проста у використанні та з усіма необхідними полями для збору інформації.",
                securityTitle: "Безпека",
                securityText: "Всі особисті дані, які збираються через форму подання заявок, мають бути захищені згідно з вимогами українського законодавства про захист даних.",
                optimizationTitle: "Мобільна оптимізація",
                optimizationText: "Сайт має оптимізуватися для перегляду на мобільних пристроях, щоб користувачі могли легко отримати доступ до інформації та подавати заявки з будь-якого пристрою.",
                seoTitle: "SEO",
                seoText: "Сайт має бути оптимізований для пошукових систем, щоб бути доступним для потенційних користувачів.",
                analyticsTitle: "Аналітика",
                analyticsText: "На сайті мають бути інструменти для відстеження відвідуваності та поведінки користувачів, щоб забезпечити постійне вдосконалення та оптимізацію.",
                designTitle: "UI/UX дизайн або графічний дизайн",
                designText: "Під час розробки UI/UX дизайну вебсторінки ми врахували декілька важливих аспектів:",
                designArray: [
                    {
                        id: "1",
                        name: 'Простота навігації',
                        text: 'Навігація по сайту має бути інтуїтивно зрозумілою. Меню - логічно структуровані, а посилання чітко позначені.',
                    },
                    {
                        id: "2",
                        name: 'Чіткість інформації',
                        text: 'Вся інформація про гранти має бути представлена чітко та зрозуміло. Важливо використовувати просту мову та уникати використання жаргону.',
                    },
                    {
                        id: "3",
                        name: 'Інтерактивність',
                        text: 'Сайт має бути інтерактивним, щоб користувачі могли легко взаємодіяти з ним. Наприклад, включити такі елементи, як інтерактивні форми подання заявок, чати для підтримки користувачів та інші елементи.',
                    },
                    {
                        id: "4",
                        name: 'Графічний дизайн',
                        text: 'Графічний дизайн сайту повинен бути привабливим та професійним. Використання кольорів, шрифтів та зображень повинно бути консистентним на всьому сайті. Крім того, дизайн повинен відображати бренд та місію організації.',
                    },
                ],
                platformTitle: "НА ЯКІЙ ПЛАТФОРМІ РОЗРОБЛЕНО САЙТ",
                platformText: `Вебсайт було створено на WordPress. Це одна з найбільших платформ для створення вебсайтів. WP має інтуїтивно зрозумілий інтерфейс, який дозволяє легко додавати та редагувати інформацію. WordPress регулярно оновлюється для забезпечення безпеки вебсайту та захисту від потенційних загроз.</br></br> На основі брендбуку організації була розроблена іднивідуальна тема WP. Тож, ми обрали корпоративні кольори, шрифти та інші елементи дизайну, щоб забезпечити цілісність айдентики у всіх елементах вебсайту.`,
                stackTitle: "Стек технологій",
                stackText: "Зважаючи на архітектуру проєкту, функціональність сайту та системні вимоги, був обраний наступний стек (набір технологій):",
                stackArray: [
                    {
                        technology: `<span class="font-bold">Tailwind CSS</span> - фреймворк з відкритим вихідним кодом, який створює список CSS-класів для стилізації кожного елемента.`
                    },
                    {
                        technology: `<span class="font-bold">Alpine.js</span> – це легкий JavaScript-фреймворк, який у поєднанні з TailwindCSS дозволяє весь код, включаючи візуальну розмітку та логіку, прописувати в HTML поруч із відповідними блоками даних.`
                    },
                    {
                        technology: `<span class="font-bold">Gulp</span> - це потокова система збірки, яка допомагає автоматизувати трудомісткі завдання у  розробницькому процесі. Він використовується для виконання різних завдань на фронтенді, таких як запуск вебсервера, використання препроцесорів, оптимізація активів, таких як CSS, JavaScript та зображення.`
                    }
                ],
                adminTitle: "Адміністративна панель",
                adminText: `В розробці програмно-адміністративної частини сайту ми застосували класичну модель WordPress admin panel. </br></br> В результаті було створено зручну адміністративну панель, яка дозволяє партнеру легко та ефективно керувати сайтом: редагувати текст, завантажувати зображення, додавати контент на різних мовах.`,
              },
        },
        pageDila: {
            project: {
                title: "ПРО ПРОЄКТ",
                text: "Фундація Діла реалізує благодійні проєкти у сфері медицини та допомагає Збройним Силам України. Здоровʼя - це соціально важливий напрямок, тому місія фундації - підтримка та зміцнення здоров’я українців шляхом надання необхідних медичних послуг та ресурсів. Серед основних видів допомоги - лабораторна діагностика.",
                dilaTitle: 'INSTAGRAM DILA FOUNDATION:',
                imgText: `Наша мета – підтримувати здоров’я українців та зробити внесок у побудову успішного майбутнього нашої держави.</br></br> Ми віримо, що від здоров’я та самопочуття кожної людини залежить розвиток економіки, культури і майбутнього країни в цілому.`,
                text2: "З початку повномасштабного вторгнення Dila Foundation спрямували 1.163.328 гривень на допомогу українським військовим. Працювали над трьома основними напрямками: адресна допомога військовим, допомога військовим госпіталям у Києві, Одесі, Дніпрі, Харкові, Львові, взаємодія з благодійними фондами."
            },
            collaboration: {
                title: "про нашу співпрацю - розробка ЛОГОТИПУ",
                tasksTitle: "Про задачі",
                tasksText: `Бренд представляє організацію, яка прагне зробити цей світ кращим через благодійність та проєкти, орієнтовані на добробут українців. Тому важливо створити правильне візуальне сприйняття бренду за допомогою певних елементів. </br></br> Основною задачею було створення логотипа, який би включав елементи дизайну від материнської компанії, але з додаванням візуальних елементів, що відображають благодійний напрямок роботи організації, яка працює у медичній сфері. </br></br> Щодо використання айдентики - задача була зберегти корпоративні кольори, але освіжити деякі з них, оновити, осучаснити, разом з тим залишаючи впізнаваність бренду. `,
                userName: "ЛІЛІЯ Моісєєва",
                userPosition: `Графічний дизайнер, Арт-директор`,
                userText: `Певний виклик, але завжди цікава задача - працювати над проєктами, які мають материнську організацію. Оскільки потрібно знайти правильну форму, винайти щось нове, але зберегти індивідуальність та впізнаваність. Приємно працювати з благодійними організаціями, тому що розумієш свою причетність до допомоги.`,
                colorTitle: "Кольори",
                colorText: `Щодо корпоративної ідентичності, було вирішено використати корпоративні кольори, але з оновленням деяких з них, щоб зберегти впізнаваність бренду. Основний колір - синій. У певних варіантах використання його можна доповнити акцентним помаранчевим кольором. Супутні кольори - білий та чорний. `,
                logoTitle: `Логотип`,
                logoAfter: `Стало`,
                logoBefore: `Було`,
                logoText: `Логотип благодійної фундації було розроблено з урахуванням сучасних тенденцій дизайну, з використанням м’яких ліній, що відображають гуманітарну місію організації.</br></br> Ми розробили брендбук з усіма необхідними елементами: основний логотип, другорядний логотип та шрифтовий логотип.</br></br> Основний логотип має як візуальний елемент, так і шрифт. Візуальна частина містить літеру D (перша літера назви організації - Dila) з плюсом посередині (сфера медицини), а також елемент долоні/руки, який символізує благодійність. Форму візуально можна вписати у прямокутник.</br></br> Другорядний логотип може використовуватись на рівні з основним, у тих випадках, коли це візуально вмотивованіше. Візуально форму можна вписати у квадрат. </br></br> Шрифтовий логотип використовується лише разом з основними, як супутній.`,
                elementTitle: `Елементи айдетики`,
                elementText: `У айдентиці бренду тепер присутні 6 основних впізнаваних елементів, які використовуються окремо або у комбінації один з одним.`,
                useTitle: `ВИКОРИСТАННЯ АЙДЕНТИКИ та елементи брендингу`,
                useText: `Зокрема, прописані правила використання логотипів та кольорів:`,
                useList: [
                    {
                        text: 'на різних фонах,',
                    },
                    {
                        text: 'у чорно-білій версії, '
                    },
                    {
                        text: 'нвертованій версії, '
                    },
                    {
                        text: 'розміри усіх видів логотипів, '
                    },
                    {
                        text: 'безпечні зони (кордони) логотипу, які не можна порушувати, '
                    },
                    {
                        text: 'заборонені маніпуляції з обертанням, нахилянням логотипа'
                    },
                    {
                        text: 'коректне використання кольорів, використанням таких елементів брендингу як патерн, акцентна лінія тощо.'
                    },
                ],
                socialTitle: `СОЦІАЛЬНІ МЕРЕЖІ`,
                socialText: `Оскільки фундація активно веде соціальні мережі, однією з задач для графічного дизайнера проєкту було оформлення візуалу для соціальних мереж. Нові елементи для шаблонів соціальних мереж розроблені згідно з новою айдентикою. Водночас усі шаблони практичні у використанні.`,
               },
        },
        pageUnity: {
            project: {
                title: "ПРО ПРОЄКТ",
                text: `Unity Foundation - благодійний фонд, діяльність якого фокусується на трьох основних напрямках: </br> 1. Допомога ЗСУ. </br> 2. Підтримка та розвиток спорту в Україні. </br> 3. Допомога дітям з інвалідністю. Розвиток інклюзивності.`,
            },
            collaboration: {
                title: "про нашу співпрацю - розробка сайту",
                text1: "Головною метою веб-сайту Unity Foundation є надання інформації про місію, цілі та проєкти фонду. Це платформа для залучення донорів, волонтерів та партнерів. Зокрема, на сайті можна публікувати новини про діяльність фундації. ",
                text2: "Серед задач було створити лаконічний, мінімалістичний водночас сучасний дизайн. Кольори, шрифти та інші елементи узгоджені з корпоративною айдентикою Unity Foundation. Сайт легкий у використанні та навігації, оптимізований для різних пристроїв: ПК, планшетів, мобільних телефонів тощо. Сайт відповідає всім стандартам безпеки та конфіденційності. Всі особисті дані, які збираються через сайт - захищені.",
                platformTitle: "НА ЯКІЙ ПЛАТФОРМІ РОЗРОБЛЕНО САЙТ",
                platformText: `Панель адміністратора Shopify - це інтерфейс, який дозволяє власникам сайту зручно керувати контентом. Він надає доступ до різних функцій та інструментів, які допомагають отримувати та аналізувати статистику.`,
                stackTitle: "Стек технологій",
                stackText: "Зважаючи на архітектуру проєкту, функціональність сайту та системні вимоги, був обраний наступний стек (набір технологій):",
                stackArray: [
                    {
                        technology: `<span class="font-bold">Tailwind CSS</span> - фреймворк з відкритим вихідним кодом, який створює список CSS-класів для стилізації кожного елемента.`
                    },
                    {
                        technology: `<span class="font-bold">Alpine.js</span> – це легкий JavaScript-фреймворк, який у поєднанні з TailwindCSS дозволяє весь код, включаючи візуальну розмітку та логіку, прописувати в HTML поруч із відповідними блоками даних.`
                    },
                    {
                        technology: `<span class="font-bold">Gulp</span> - це потокова система збірки, яка допомагає автоматизувати трудомісткі завдання у  розробницькому процесі. Він використовується для виконання різних завдань на фронтенді, таких як запуск вебсервера, використання препроцесорів, оптимізація активів, таких як CSS, JavaScript та зображення.`,
                    }
                ],
                adminTitle: "Адміністративна панель",
                adminText: `Адміністративна панель сайту дозволяє керувати всіма аспектами вебсайту в одному місці. Вона надає інструменти для додавання та редагування контенту, управління структурою сайту та налаштування прав доступу. Таким чином, адміністративна панель спрощує процес управління сайтом, роблячи його швидким та зручним.`,
                multilanguageTitle: "МУЛЬТИМОВНІСТЬ",
                multilanguageText: `Сайт має дві мовні версії: українську та англійську.</br></br>У цьому проєкті ми відмовилися від автоматичного перекладу попри додаткові витрати на професійний переклад контенту.</br></br> Автоматичний переклад здійснюється лише в межах адміністративної панелі, невидимої користувачам.`,

            },
        },
    },
};
